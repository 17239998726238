import { forwardRef, useState, ChangeEventHandler } from 'react';
import cx from 'classnames';

type TImageInputProps = {
  defaultValue?: string;
  handleInputFocus?: ChangeEventHandler<HTMLInputElement>;
};

export const ImageInput = forwardRef<HTMLInputElement, TImageInputProps>(
  ({ defaultValue, handleInputFocus, ...props }, ref) => {
    const [previewImage, setPreviewImage] = useState(defaultValue);

    const handleFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      handleInputFocus?.(e);
      const file = e.target.files?.[0];
      if (file) {
        const previewImageURL = URL.createObjectURL(file);
        setPreviewImage(previewImageURL);
      } else {
        setPreviewImage('');
      }
    };

    return (
      <div className="flex justify-center">
        <div
          className={cx('relative w-20 h-20 md:w-[150px] md:h-[150px] rounded-full', {
            'bg-user-placeholder-color': !previewImage,
          })}
        >
          {!!previewImage && (
            <div className="h-full w-full rounded-full flex justify-center overflow-hidden">
              <img className="w-full h-full object-cover" alt="user-profile-img" src={previewImage} />
            </div>
          )}
          <div className="cursor-pointer flex justify-center items-center absolute bg-white-100 w-7 h-7 border-2 border-white-100 md:w-9 md:h-9 rounded-full after:content-editPencilBlack after:w-[19px] after:h-[19px] right-0 bottom-0 md:bottom-2" />
          <input
            ref={ref}
            className="opacity-0 absolute inset-0 cursor-pointer"
            type="file"
            accept="image/*"
            {...props}
            onChange={handleFileChange}
          />
        </div>
      </div>
    );
  },
);
