import React, { FC } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import uniqBy from 'lodash/uniqBy';
import { getLinkRelAttributeValue } from 'utils/getLinkRelAttributeValue';
import { TMember } from '../TerritoryMembersPopUp/MemberProfile';
import ArticleInteractionsProfilesListElement from '../ArticleInteractions/ArticleInteractionsProfilesListElement';
import UserProfileImage from '../UserProfileImage/UserProfileImage';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import ArticleInteractionsCopy from '../Article/ArticleInteractionsCopy';
import { getUserPageUrl } from '../../services/pagesUrl/getUserPageUrl';
import { T2ProfileCardContainer } from '../ProfileCard/T2ProfileCardContainer';
import { UnstyledTooltip } from '../Tooltip/UnstyledTooltip';
import { PROFILE_CARD_HOVER_DELAY } from '../ProfileCard/constants';
import { NUMBER_OF_PROFILE_IMAGES } from './constants';
import { TAvatarSize } from '../UserProfileImage/types';
import { useTerritoryDetailsModalContext } from '../TerritoryDetails/context/TerritoryDetailsModalContextProvider';

type TTerritoryMembersProps = {
  members: TMember[];
  membersCount: number;
  territoryName?: string;
  interactionsCopyClassname?: string;
  avatarClassname?: string;
  isVisible?: boolean;
  profileImageSize?: TAvatarSize;
  numberOfProfileImages?: number;
  hideMembersCopy?: boolean;
};

const TerritoryMembers: FC<TTerritoryMembersProps> = ({
  members = [],
  membersCount,
  interactionsCopyClassname,
  avatarClassname,
  isVisible,
  profileImageSize = 'medium',
  numberOfProfileImages = NUMBER_OF_PROFILE_IMAGES,
  hideMembersCopy = false,
}) => {
  const { handleOpenTerritoryMembersModal } = useTerritoryDetailsModalContext();
  const uniqueMembers = uniqBy(members, 'id');
  const profileImagesArray = uniqueMembers?.slice(0, numberOfProfileImages).filter(Boolean);
  const actualMembersCount = membersCount - (members.length - uniqueMembers.length);
  const shouldDisplayModal = profileImagesArray.length < actualMembersCount;

  return (
    <ul className="list-none flex flex-row items-center justify-start">
      {profileImagesArray.map(({ id, username, profileImage }, index) => {
        const userProfileUrl = getUserPageUrl(username as string);
        return (
          <ArticleInteractionsProfilesListElement
            className={cx({
              '[&:not(:first-of-type)]:ml-[-10px] [&:not(:first-of-type)]:mt-0': true,
              '[&:not(:first-of-type)]:ml-[-10px]': false,
            })}
            // eslint-disable-next-line react/no-array-index-key
            key={`${id}-${index}`}
          >
            <UnstyledTooltip
              direction="bottom"
              floatingUIHover={PROFILE_CARD_HOVER_DELAY}
              trigger={
                <div className="flex items-center justify-center">
                  <Link
                    className="absolute inset-0 w-full h-full z-20"
                    href={userProfileUrl}
                    target="_blank"
                    rel={getLinkRelAttributeValue(userProfileUrl)}
                  >
                    {' '}
                  </Link>
                  <UserProfileImage
                    profileImage={profileImage as TCloudinaryImageShape}
                    size={profileImageSize}
                    className={avatarClassname}
                    isVisible={isVisible}
                  />
                </div>
              }
            >
              <T2ProfileCardContainer id={id as string} />
            </UnstyledTooltip>
          </ArticleInteractionsProfilesListElement>
        );
      })}
      {actualMembersCount > 0 && shouldDisplayModal && (
        <button
          className="ml-2 flex gap-x-1 items-center justify-center group"
          onClick={handleOpenTerritoryMembersModal}
        >
          <ArticleInteractionsCopy className={cx('text-white-100', interactionsCopyClassname)}>
            +{actualMembersCount - profileImagesArray.length}
          </ArticleInteractionsCopy>
          {!!profileImagesArray.length && true && !hideMembersCopy && (
            <ArticleInteractionsCopy
              className={cx('hidden md:block text-white-100 whitespace-nowrap', interactionsCopyClassname)}
            >
              members
            </ArticleInteractionsCopy>
          )}
        </button>
      )}
    </ul>
  );
};
export default TerritoryMembers;
