export const TERRITORIES_COMMON_DATA = `
  id
  createdAt
  name
  slug
  order
  logo {
    id
    publicUrl
    publicUrlTransformed
  }
  description
  articlesCount(where: { status: { equals: "published" }, type: { equals: ARTICLE } })
  totalTimePoints
  membershipsCount(where: { status: { equals: ACTIVE } })
  uniqueMembers(take: 5, where: { status: { equals: ACTIVE } }) {
    id
    profileImage {
      ...BaseImageFields
    }
  }
  memberships(take: 5, where: { status: { equals: ACTIVE } }) {
    id
    user {
      id
      profileImage {
        ...BaseImageFields
      }
    }
  }
`;
