import { Article, NftReference } from 't2-graphql-types';

export const enum ArticleType {
  Article = 'ARTICLE',
  Prompt = 'PROMPT',
  Quote = 'QUOTE',
}

export type TSortedArticlesReturn = { articles: Article[]; articlesCount: number };

export type TCollectData = {
  hasOnchainNftReference: boolean;
  loading: boolean;
  nftReference: NftReference | null;
};
