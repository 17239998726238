import React, { useEffect, useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import PromptsListSection from '../PromptsListSection/PromptsListSection';
import { useGetLensPublicationsStats } from '../../services/lens/hooks/useGetLensPublicationsStats';
import { TPromptsData } from '../PromptsListSection/types';
import { PromptListPurpose, PromptsSortingDropdownKey } from '../PromptsListSection/constants';
import { getSortedPromptsQuery } from '../Prompt/queries/getSortedPromptsQuery';
import { TERRITORY_PROMPTS_SIZE } from '../Territory/TerritoryPromptsSection/constants';
import { ArticleType } from '../Article/types';

const HomepagePromptsContainer = () => {
  const PAGE_SIZE = 6;
  const [sortBy, setSortBy] = useState(PromptsSortingDropdownKey.RecommendedPrompts as string);
  const [getSortedPrompts, { data, loading, error }] = useLazyQuery<{
    getSortedArticles: { articles: TPromptsData[]; articlesCount: number };
  }>(getSortedPromptsQuery, {
    variables: {
      skip: 0,
      take: PAGE_SIZE,
      where: { type: { equals: ArticleType.Prompt } },
      sortBy,
    },
  });
  const getNextSortedPrompts = useCallback((variables) => getSortedPrompts({ variables }), [getSortedPrompts]);

  useEffect(() => {
    getSortedPrompts();
  }, []);
  const prompts = data?.getSortedArticles?.articles;
  const totalItems = data?.getSortedArticles?.articlesCount || 0;
  const { articlesWithLensStats, lensStatsLoading } = useGetLensPublicationsStats(prompts || []);

  if (error) {
    return null;
  }

  const onSortByChange = (sortBy: string) => {
    setSortBy(sortBy);
    getSortedPrompts({
      variables: {
        skip: 0,
        take: TERRITORY_PROMPTS_SIZE,
        sortBy,
      },
    });
  };

  return (
    <PromptsListSection
      titleText="Prompts"
      titleTooltipText="Create a prompt to spark new discussion, or respond to one with your own post!"
      noPromptsMessage="No prompts yet!"
      totalItems={totalItems}
      pageSize={PAGE_SIZE}
      loading={loading}
      error={!!error}
      refetch={getNextSortedPrompts}
      prompts={articlesWithLensStats as TPromptsData[]}
      showTerritoryTag
      titleTooltipDirection="right"
      lensStatsLoading={lensStatsLoading}
      purpose={PromptListPurpose.latestPrompts}
      onSortByChange={onSortByChange}
      sortBy={sortBy}
      defaultSortBy={PromptsSortingDropdownKey.RecommendedPrompts}
      showCounter={false}
    />
  );
};
export default HomepagePromptsContainer;
