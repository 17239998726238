import React, { FC } from 'react';
import Image from 'next/image';
import HeroSlideWrapper from './HeroSlideWrapper';
import HeroSlideContentWrapper from './HeroSlideContentWrapper';
import Typo from '../Typography';
import LinkButton from '../LinkButton/LinkButton';
import HeroSlideImageWrapper from './HeroSlideImageWrapper';
import { THeroSlideProps } from './types';
import { FRIENDS_WHO_WRITE_S3_LINK } from './constants';

type TFriendsWhoWriteSlideProps = THeroSlideProps;

export const FriendsWhoWriteSlide: FC<TFriendsWhoWriteSlideProps> = ({ onButtonClick }) => (
  <HeroSlideWrapper className="flex flex-col sm:flex-row relative !bg-t2-primary-beige">
    <div className="sm:hidden">
      <Image
        src="/images/FWW-banner-s3-mobile.svg"
        width={200}
        height={130}
        objectFit="cover"
        alt="an image of a two people standing on a keyboard"
      />
    </div>
    <HeroSlideContentWrapper className="relative sm:basis-2/3 basis-full">
      <Typo tag="h2" className="text-4xl md:text-5xl xl:text-[55px] text-t2-neutral-grey09 font-primary font-semibold">
        <span className="italic text-t2-primary-brandColor">Friends Who Write</span>
        <span className="block">Registration opens now!</span>
      </Typo>
      <Typo variant="p2" className="text-t2-neutral-grey07">
        Our flagship writing contest is back! Gather your friends this November, support each other to the very end, and
        win from <span className="font-semibold">a prize pool of over $7,000!</span>
      </Typo>
      <LinkButton
        onClick={onButtonClick}
        variant="secondary"
        href={FRIENDS_WHO_WRITE_S3_LINK}
        className="!px-12 !text-t2-shades-white"
      >
        Register now
      </LinkButton>
    </HeroSlideContentWrapper>
    <HeroSlideImageWrapper className="relatve sm:basis-1/3">
      <div className="sm:h-[120px] sm:w-[200px] md:h-[120px] md:w-[220px] lg:h-[250px] lg:w-[310px]">
        <Image
          src="/images/FWW-banner-s3-desktop.svg"
          width={340}
          height={236}
          objectFit="cover"
          alt="an image of a two people standing on a keyboard"
          className="relative mt-6 -top-20"
        />
      </div>
    </HeroSlideImageWrapper>
  </HeroSlideWrapper>
);
