import React, { FC, ReactNode } from 'react';
import { ListSectionTitle } from './ListSectionTitle';
import { TToolTipDirection } from '../Tooltip/types';

export type TListSectionProps = {
  titleText?: string;
  titleClassName?: string;
  titleTooltipText?: string;
  titleActionButton?: ReactNode;
  children: ReactNode;
  className?: string;
  pagination?: ReactNode;
  totalItems?: number;
  titleTooltipDirection?: TToolTipDirection;
  loading?: boolean;
  sortingDropdown?: ReactNode;
  showCounter?: boolean;
};

export const ListSection: FC<TListSectionProps> = ({
  titleText,
  titleClassName,
  titleTooltipText,
  titleActionButton,
  children,
  className,
  totalItems,
  pagination,
  titleTooltipDirection,
  loading,
  sortingDropdown,
  showCounter,
}) => (
  <div className={className}>
    {titleText && (
      <div className="flex justify-between">
        <ListSectionTitle
          text={titleText}
          itemsCount={totalItems}
          className={titleClassName}
          tooltipText={titleTooltipText}
          tooltipDirection={titleTooltipDirection}
          loading={loading}
          sortingDropdown={sortingDropdown}
          showCounter={showCounter}
        />
        {titleActionButton}
      </div>
    )}
    {children}
    {pagination}
  </div>
);
