import { TAuthenticateUserMemberships, TFormattedTerritory, TNormalizedTerritory, TTerritory } from '../types';
import getTerritoryPageUrl from '../../../services/pagesUrl/getTerritoryPageUrl';

export const sortTerritories = (a: TFormattedTerritory, b: TFormattedTerritory): number => {
  if (!a.isMember && b.isMember) {
    return 1;
  }
  if (a.isMember && !b.isMember) {
    return -1;
  }
  if (+a.totalTimePoints < +b.totalTimePoints) {
    return 1;
  }
  if (+a.totalTimePoints > +b.totalTimePoints) {
    return -1;
  }
  return 0;
};

export const getTerritoryMemberStatus = (
  territory: TTerritory,
  authenticatedUserTerritoryMemberships: TAuthenticateUserMemberships,
) =>
  authenticatedUserTerritoryMemberships?.some(
    (userMembership) => userMembership?.status === 'ACTIVE' && userMembership?.role?.territory?.id === territory?.id,
  );

export const getFormattedAndSortedTerritoryData = (
  territories: TTerritory[],
  authenticatedUserTerritoryMemberships: TAuthenticateUserMemberships,
): TNormalizedTerritory[] =>
  territories
    ?.map((territory: TTerritory) => ({
      ...territory,
      url: getTerritoryPageUrl(territory?.slug),
      totalTimePoints: Math.floor(territory?.totalTimePoints),
      isMember: getTerritoryMemberStatus(territory, authenticatedUserTerritoryMemberships),
    }))
    .sort(sortTerritories);
