import React, { FC } from 'react';
import cx from 'classnames';
import { THeroSlideContentWrapperProps } from './HeroSlideContentWrapper';

interface IHeroSlideImageWrapperProps extends THeroSlideContentWrapperProps {
  className?: string;
}

const HeroSlideImageWrapper: FC<IHeroSlideImageWrapperProps> = ({ children, className }) => (
  <div className={cx('hidden sm:flex justify-end', className)}>{children}</div>
);

export default HeroSlideImageWrapper;
