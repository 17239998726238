import React, { FC } from 'react';
import Link from 'next/link';
import cx from 'classnames';
import { DEFAULT_TERRITORY_LOGO } from '../TerritoryHeader/constants';
import Tooltip from '../Tooltip/Tooltip';
import Typo from '../Typography/Typo';
import getTerritoryPageUrl from '../../services/pagesUrl/getTerritoryPageUrl';
import { TMutualTerritoriesProps } from './types';

const NUMBER_OF_TERRITORY_IMAGES = 5;

type TProfileCardMutualTerritoriesProps = {
  territories: TMutualTerritoriesProps[];
};

export const T2ProfileCardMutualTerritories: FC<TProfileCardMutualTerritoriesProps> = ({ territories }) => {
  const territoryImagesArray = territories?.slice(0, NUMBER_OF_TERRITORY_IMAGES);

  return (
    <ul className="list-none flex flex-row items-center justify-start">
      {territoryImagesArray.map(({ id, name, slug, logo }, index) => (
        <li
          className={cx('relative rounded-full w-fit border-2 border-white-100 bg-white-100 hover:z-10', {
            '[&:not(:first-of-type)]:ml-[-10px] [&:not(:first-of-type)]:mt-0': true,
            '[&:not(:first-of-type)]:ml-[-10px]': false,
          })}
          // eslint-disable-next-line react/no-array-index-key
          key={`${id}-${index}`}
        >
          <Tooltip
            direction="bottom"
            trigger={
              <Link href={getTerritoryPageUrl(slug as string)}>
                <img
                  src={logo?.publicUrlTransformed || DEFAULT_TERRITORY_LOGO}
                  alt={name as string}
                  className="object-cover rounded-full w-6 h-6"
                />
              </Link>
            }
          >
            t/{name}
          </Tooltip>
        </li>
      ))}

      {territories.length > NUMBER_OF_TERRITORY_IMAGES && (
        <Typo variant="small-p" className="!text-sm ml-2 text-t2-neutral-grey05">
          +{territories.length - NUMBER_OF_TERRITORY_IMAGES} Mutual Territories
        </Typo>
      )}
      {territories.length < NUMBER_OF_TERRITORY_IMAGES && (
        <Typo
          variant="small-p"
          className={cx('!text-sm ml-2 text-t2-neutral-grey05', {
            '!ml-0': territories.length === 0,
          })}
        >
          {territories.length === 0 ? 'No mutual Territories yet' : `${territories.length} Mutual Territories`}
        </Typo>
      )}
    </ul>
  );
};
