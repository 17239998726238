import { PUBLIC_URL } from 'config';

export const enum HomepageHeroSlideName {
  eoy = 'eoy_slide',
  friendsWhoWrite = 'friends_who_write_slide',
  thpV2 = 'thp_v2_slide',
  fansBootcamp = 'fans_bootcamp_slide',
}
export const FRIENDS_WHO_WRITE_S3_LINK = `${PUBLIC_URL}/fww3`;
export const THP_V2_POST_LINK = 'https://app.t2.world/article/cm2ancmj1730911zmcs12bhx74';
export const FANS_BOOTCAMP_LINK = `${PUBLIC_URL}/1000fans`;
export const EOY_LINK = `https://app.t2.world/article/cm4ws7taw357602tnmcd3ojy65n`;
