import { TSortingDropdownItems } from '../SortingDropdown/types';

export const TERRITORY_ARTICLES_PAGE_SIZE = 10;

export enum ArticlesSortingDropdownKey {
  Newest = 'Newest',
  Oldest = 'Oldest',
  MostTimePoints = 'MostTimePoints',
  RecommendedArticles = 'RecommendedArticles',
}

export const ARTICLES_SORTING_DROPDOWN_ITEMS: TSortingDropdownItems[] = [
  {
    name: ArticlesSortingDropdownKey.Newest,
    label: 'Newest',
  },
  {
    name: ArticlesSortingDropdownKey.Oldest,
    label: 'Oldest',
  },
  {
    name: ArticlesSortingDropdownKey.MostTimePoints,
    label: 'Most Time Points',
  },
  {
    name: ArticlesSortingDropdownKey.RecommendedArticles,
    label: 'Recommended',
  },
];

export const MAX_PINNED_ARTICLES = 'Max number of articles already pinned in this territory';
export const MAX_PINNED_ARTICLES_PER_TERRITORY = 4;
