import React from 'react';

type TCheckIconProps = {
  fill?: string;
  className?: string;
};

export const CheckIcon = ({ className, fill = 'black' }: TCheckIconProps) => (
  <svg className={className} width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7069 0.292787C13.8944 0.480314 13.9997 0.734622 13.9997 0.999786C13.9997 1.26495 13.8944 1.51926 13.7069 1.70679L5.70692 9.70679C5.51939 9.89426 5.26508 9.99957 4.99992 9.99957C4.73475 9.99957 4.48045 9.89426 4.29292 9.70679L0.292919 5.70679C0.110761 5.51818 0.00996641 5.26558 0.0122448 5.00339C0.0145233 4.74119 0.119692 4.49038 0.3051 4.30497C0.490508 4.11956 0.741321 4.01439 1.00352 4.01211C1.26571 4.00983 1.51832 4.11063 1.70692 4.29279L4.99992 7.58579L12.2929 0.292787C12.4804 0.105316 12.7348 0 12.9999 0C13.2651 0 13.5194 0.105316 13.7069 0.292787Z"
      fill={fill}
    />
  </svg>
);
