import isEmpty from 'lodash/isEmpty';
import { getAllTerritoriesQuery } from '../TerritoryList/queries/getAllTerritoriesQuery';
import { useAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import HomepageTerritoriesTitle from './HomepageTerritoriesTitle';
import TerritorySlider from './TerritorySlider';
import { HomepageCreateTerritorySection } from './HomepageCreateTerritorySection';
import { TTerritory } from '../Territory/types';
import { getFormattedAndSortedTerritoryData } from '../Territory/services/getFormattedAndSortedTerritoryData';
import { TTerritoryCardProps } from './TerritoryCard';
import { useT2Query } from '../../hooks/useT2Query';
import { getUserActiveMemberships } from '../Permissions/services/getUserActiveMemberships';

const HomepageTerritories = () => {
  const { data: userData, isAuthenticated } = useAuthenticatedUser();
  const { data, error, loading } = useT2Query<{ territories: TTerritory[] }>(getAllTerritoriesQuery, {
    returnPartialData: true,
  });

  if (isEmpty(data?.territories) || error) {
    return null;
  }

  const formattedTerritories = getFormattedAndSortedTerritoryData(
    data?.territories as TTerritory[],
    getUserActiveMemberships(userData?.user),
  );
  return (
    <>
      <div className="w-full flex justify-between items-center">
        <div className="w-min xs:w-fit">
          <HomepageTerritoriesTitle />
        </div>
        <HomepageCreateTerritorySection isUserAuthenticated={isAuthenticated} />
      </div>
      <TerritorySlider territories={formattedTerritories as TTerritoryCardProps[]} isLoading={loading} />
    </>
  );
};
export default HomepageTerritories;
