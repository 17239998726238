import isEmpty from 'lodash/isEmpty';
import React, { FC, ReactNode } from 'react';
import { ListSection } from '../ListSection/ListSection';
import { Pagination } from '../Pagination/Pagination';
import Typo from '../Typography';
import getUserDisplayName from '../../../utils/getUserDisplayName';
import { getPromptPageUrl } from '../../services/pagesUrl/getPromptPageUrl';
import { getUserPageUrl } from '../../services/pagesUrl/getUserPageUrl';
import { TPromptsData } from './types';
import { PromptListPurpose, PROMPTS_SORTING_DROPDOWN_ITEMS } from './constants';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { PromptCardContainer } from '../PromptCard/PromptCardContainer';
import { PromptCardLoader } from '../PromptCard/PromptCardLoader';
import { SortingDropdown } from '../SortingDropdown/SortingDropdown';
import { TToolTipDirection } from '../Tooltip/types';
import { PaginationPurpose } from '../Pagination/constants';

type TPromptsListSectionProps = {
  prompts: TPromptsData[];
  titleActionButton?: ReactNode;
  totalItems: number;
  pageSize: number;
  titleText?: string;
  titleTooltipText?: string;
  noPromptsMessage?: string;
  titleTooltipDirection?: TToolTipDirection;
  loading: boolean;
  error: boolean;
  refetch: Function;
  showTerritoryTag?: boolean;
  lensStatsLoading?: boolean;
  purpose: PromptListPurpose;
  onSortByChange?: (sortBy: string) => void;
  sortBy?: string;
  territoryId?: string;
  defaultSortBy?: string;
  showSortingDropdown?: boolean;
  pagination?: {
    purpose?: PaginationPurpose;
  };
  showCounter?: boolean;
};

const PromptsListSection: FC<TPromptsListSectionProps> = ({
  titleText,
  prompts,
  titleActionButton,
  titleTooltipText,
  titleTooltipDirection,
  totalItems,
  pageSize,
  loading,
  error,
  refetch,
  showTerritoryTag,
  noPromptsMessage,
  lensStatsLoading,
  purpose,
  onSortByChange,
  sortBy,
  territoryId,
  defaultSortBy,
  showSortingDropdown = true,
  pagination,
  showCounter = true,
}) => {
  const { sendEvent } = useAnalytics();
  const renderPrompts = () =>
    prompts?.map((prompt) => {
      const {
        id,
        title,
        commentsCount,
        childArticlesCount,
        slug,
        author: { name, username, profileImage },
        territory,
        lensPublication,
        promptSettings,
        territoryPinnedAt,
      } = prompt;
      const authorLabel = getUserDisplayName({ name, username });
      const authorProfileURL = getUserPageUrl(username);
      const url = getPromptPageUrl(slug);

      const onPromptCardClick = () => {
        sendEvent('prompt_card_click', { prompt_id: id, prompt_purpose: purpose, prompt_title: title });
      };

      return (
        <PromptCardContainer
          id={id}
          title={title}
          commentsCount={commentsCount}
          postsCount={childArticlesCount}
          authorLabel={authorLabel}
          authorProfileImage={profileImage}
          authorProfileURL={authorProfileURL}
          territoryTitle={showTerritoryTag ? territory?.name : ''}
          territoryTagColor={territory?.tagColor as string}
          onClick={onPromptCardClick}
          url={url}
          key={id}
          lensStatsLoading={lensStatsLoading}
          lensPublication={lensPublication}
          promptSettings={promptSettings}
          territoryPinnedAt={territoryPinnedAt}
          territoryId={territoryId}
        />
      );
    });
  const renderPromptCardLoaders = () =>
    Array.from({ length: pageSize }).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <PromptCardLoader key={index} />
    ));

  const renderListSectionBody = () => {
    if (loading) {
      return renderPromptCardLoaders();
    }

    const prompts = renderPrompts();
    if (isEmpty(prompts) && noPromptsMessage) {
      return (
        <div className="flex my-4 md:mt-16 md:mb-10 col-span-full justify-self-center">
          <Typo variant="p2">{noPromptsMessage}</Typo>
        </div>
      );
    }

    return prompts;
  };

  return (
    <ListSection
      className="w-full col-span-full lg:col-start-2 lg:col-end-12 md:my-8"
      titleClassName="mb-8"
      titleText={titleText}
      titleTooltipText={titleTooltipText}
      titleTooltipDirection={titleTooltipDirection}
      titleActionButton={titleActionButton}
      totalItems={totalItems}
      loading={loading}
      showCounter={showCounter}
      pagination={
        <Pagination
          pageSize={pageSize}
          totalItems={totalItems}
          refetch={refetch}
          loading={loading}
          error={error}
          pageResetKey={`${sortBy}-${territoryId}`}
          purpose={pagination?.purpose}
        />
      }
      sortingDropdown={
        showSortingDropdown ? (
          <SortingDropdown
            items={PROMPTS_SORTING_DROPDOWN_ITEMS}
            onSortByChange={onSortByChange}
            purpose={purpose}
            territoryId={territoryId}
            defaultSortBy={defaultSortBy}
          />
        ) : null
      }
    >
      <div className="grid col-span-full gap-3 md:grid-cols-2 md-gap-4 lg:grid-cols-3 lg:col-start-2 lg:gap-5">
        {renderListSectionBody()}
      </div>
    </ListSection>
  );
};
export default PromptsListSection;
