import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import ProtectWithLogin from '../ProtectWithLogin/ProtectWithLogin';
import { getUserPageUrl } from '../../services/pagesUrl/getUserPageUrl';
import { TAvatarSize } from '../UserProfileImage/types';
import AuthorDetail from './AuthorDetail';
import { TTextSize } from '../UserDetail/UserDetail';

export type TAuthorProps = {
  name?: string;
  username: string;
  profileImage?: TCloudinaryImageShape;
  profileImageSize?: TAvatarSize;
  publicAddress?: string;
  shouldDisplayNameOnDesktopDevices?: boolean;
  textSize?: TTextSize;
  onClick?: () => void;
};

export const AuthorDetailContainer: React.FC<TAuthorProps> = ({ username, ...props }) => (
  <ProtectWithLogin redirectUrl={getUserPageUrl(username)}>
    <AuthorDetail username={username} {...props} />
  </ProtectWithLogin>
);
