import { gql } from '@apollo/client';
import { TERRITORIES_COMMON_DATA } from '../../Territory/queries/constants';

export const getAllTerritoriesQuery = gql`
  query getAllTerritoriesQuery {
    territories(orderBy: { order: asc }) {
      ${TERRITORIES_COMMON_DATA}
    }
  }
`;
