import React, { FC } from 'react';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import DropdownItem from '../Dropdown/DropdownItem';
import DropdownClickableItem from '../Dropdown/DropdownClickableItem';
import Typo from '../Typography';
import { CheckIcon } from '../Icons/CheckIcon';
import { TSortingDropdownItems } from './types';
import { PromptListPurpose } from '../PromptsListSection/constants';

type TSortingDropdownItemsProps = {
  items: TSortingDropdownItems[];
  value: string;
  onChange: (sortBy: string) => void;
  purpose: PromptListPurpose;
  territoryId?: string;
};

export const SortingDropdownItems: FC<TSortingDropdownItemsProps> = ({
  items = [],
  value,
  onChange,
  purpose,
  territoryId,
}) => {
  const { sendEvent } = useAnalytics();

  const onClick = (name: string) => {
    onChange?.(name);

    const sendEventProps = {
      name,
      ...(territoryId ? { territoryId } : {}),
      purpose,
    };

    sendEvent('sorting_dropdown_item_on_territory_prompts_list_click', sendEventProps);
  };

  return (
    <>
      <DropdownItem key="label" className="max-h-[48px] px-4 py-2">
        <Typo className="!text-base text-t2-neutral-grey05">Sort By</Typo>
      </DropdownItem>
      {items.map(({ name, label }) => (
        <DropdownItem key={name} className="max-h-[48px]">
          <DropdownClickableItem className="items-center" onClick={() => onClick(name)}>
            <div className="w-full text-left">{label}</div>
            <div className="ml-3">{name === value && <CheckIcon />}</div>
          </DropdownClickableItem>
        </DropdownItem>
      ))}
    </>
  );
};
