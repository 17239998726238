import React, { FC } from 'react';
import cx from 'classnames';
import HeroSlideWrapper from './HeroSlideWrapper';
import HeroSlideContentWrapper from './HeroSlideContentWrapper';
import Typo from '../Typography/Typo';
import LinkButton from '../LinkButton/LinkButton';
import HeroSlideImageWrapper from './HeroSlideImageWrapper';
import { THeroSlideProps } from './types';
import { FANS_BOOTCAMP_LINK } from './constants';

type TFansBootcampSlideProps = THeroSlideProps;

export const FansBootcampSlide: FC<TFansBootcampSlideProps> = ({ onButtonClick }) => (
  <HeroSlideWrapper className="md:!bg-t2-primary-beige relative justify-between px-5 md:px-14 xl:px-24 before:absolute before:inset-0 before:bg-fans-bootcamp-desktop before:rounded-[24px] before:-z-0 before:bg-no-repeat before:bg-cover">
    <HeroSlideContentWrapper className="flex items-center sm:items-start z-10">
      <div className="flex flex-col gap-4 items-center justify-center sm:justify-start">
        <div className="relative md:hidden before:bg-center w-[175px] h-[100px] before:absolute before:inset-0 before:bg-fans-bootcamp-peach before:bg-no-repeat before:bg-contain" />
        <div
          className={cx(
            'w-[288px] h-[69px] lg:w-[350px] lg:h-[105px] xl:w-[400px] xl:h-[105px]',
            'relative before:absolute before:inset-0 before:bg-no-repeat before:bg-contain before:h-full before:w-full',
            'before:bg-fans-bootcamp-title-mobile before:md:bg-fans-bootcamp-title-desktop',
          )}
        />
        <Typo variant="small-p" className="md:self-start !text-xl md:!text-[28px] !font-semibold text-t2-shades-white">
          for writers on the internet
        </Typo>
      </div>
      <Typo variant="p2" className="!text-base md:!text-lg text-t2-primary-beige">
        Let’s crack the code of the Creator Economy 4.0 together! Explore a brand new approach to publishing and
        monetizing, and win prizes in our exciting Territory Race.
      </Typo>
      <LinkButton
        onClick={onButtonClick}
        variant="secondary"
        className="!px-12 !text-t2-neutral-grey08 !bg-[#FCB4B4]"
        href={FANS_BOOTCAMP_LINK}
      >
        Register Now
      </LinkButton>
    </HeroSlideContentWrapper>
    <HeroSlideImageWrapper className="">
      <div className="relative before:bg-center md:w-[300px] md:h-[300px] xl:w-[350px] xl:h-[300px] before:absolute before:inset-0 before:bg-fans-bootcamp-peach before:bg-no-repeat before:bg-contain" />
    </HeroSlideImageWrapper>
  </HeroSlideWrapper>
);
