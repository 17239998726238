import { FormEventHandler, forwardRef } from 'react';
import cx from 'classnames';
import { AutoSizeTextArea } from '../AutoSizeTextArea/AutoSizeTextArea';
import Fieldset from '../Fieldset/Fieldset';
import { TTextAreaProps } from './types';

const TextArea = forwardRef<HTMLTextAreaElement, TTextAreaProps>(
  ({ className, label, placeholder, rows, disabled, errorMessage, onChange, readOnly, ...props }, ref) => (
    <Fieldset readOnly={readOnly} disabled={disabled} className={className} errorMessage={errorMessage} label={label}>
      <AutoSizeTextArea
        ref={ref}
        className={cx(
          'w-full h-full pb-5 pt-12 px-4 bg-white-900 border border-t2-neutral-grey03 outline-none focus:border-t2-neutral-grey05 rounded-[8px] disabled:text-t2-neutral-grey05',
          {
            'border-red-800': !!errorMessage,
          },
        )}
        disabled={disabled}
        placeholder={placeholder}
        readOnly={readOnly}
        rows={rows}
        onChange={onChange as unknown as FormEventHandler<HTMLTextAreaElement>}
        {...props}
      />
    </Fieldset>
  ),
);
export default TextArea;
