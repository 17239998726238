import { gql } from '@apollo/client';
import { ARTICLE_CARD_FIELDS } from 'src/components/TerritoryArticlesSection/queries/articleCardDataFragment';
import {
  articlesQueryFragment,
  articlesWithoutUserRelationsQueryFragment,
} from 'src/components/TerritoryArticlesSection/queries/articlesQueryFragment';

export const getRecommendedPostsQuery = gql`
  ${ARTICLE_CARD_FIELDS}
  query getRecommendedPostsQuery($userId: ID!, $type: ArticleTypeType!, $skip: Int!, $take: Int!) {
    listing(where: { name: RecommendedPosts }) {
      articlesCount(where: { status: { equals: "published" }, deactivated: { equals: false } })
      ${articlesQueryFragment()}
    }
  }
`;

export const getRecommendedPostsWithoutUserRelationsQuery = gql`
  ${ARTICLE_CARD_FIELDS}
  query getRecommendedPostsWithoutUserRelationsQuery($type: ArticleTypeType!, $skip: Int!, $take: Int!) {
    listing(where: { name: RecommendedPosts }) {
      articlesCount(where: { status: { equals: "published" }, deactivated: { equals: false } })
      ${articlesWithoutUserRelationsQueryFragment()}
    }
  }
`;
