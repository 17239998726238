export const enum AnalyticsFooterItemName {
  home = 'home_page',
  twitter = 'twitter',
  discord = 'discord',
  hey = 'hey',
  manifesto = 'manifesto',
  docs = 'docs',
  termsOfService = 'terms_of_service',
  privacyNotice = 'privacy_notice',
}
