import React, { FC, ReactNode } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import Link from 'next/link';
import {
  MAX_CHARACTERS_IN_TERRITORY_NAME,
  TERRITORY_NAME_REGEX,
  TERRITORY_NAME_REGEX_ERROR_MESSAGE,
} from 't2-keystone/src/schema/Territory/validations/constants';
import {
  ROLE_TERRITORY_MEMBERSHIP_CONST_MAX,
  ROLE_TERRITORY_MEMBERSHIP_CONST_MIN,
} from 't2-keystone/src/schema/Role/contants';
import { Form } from '../Form/Form';
import TextInput from '../TextInput/TextInput';
import TextArea from '../TextArea/TextArea';
import Typo from '../Typography/Typo';
import { TTerritoryFields } from './types';
import Tooltip from '../Tooltip/Tooltip';
import InformationIcon from '../Icons/InformationIcon';
import { generateTerritoryUrlFromTerritoryName } from './services';
import { ImageInput } from '../UserSettings/ImageInput';
import { validateInputImage } from '../UserSettings/validators/validateInputImage';
import {
  DEFAULT_TERRITORY_MEMBERSHIP_COST,
  EMPTY_TERRITORY_PAGE_URL,
  FormType,
  TERRITORY_GUIDELINES_URL,
  TERRITORY_V1_URL,
} from './constants';

type TTerritoryFormProps = {
  useFormReturn: UseFormReturn<TTerritoryFields>;
  onSubmit: (data: TTerritoryFields) => void;
  formType: FormType;
  submitButton: ReactNode;
  hideMembershipCost: boolean;
};

export const TerritoryForm: FC<TTerritoryFormProps> = ({
  useFormReturn,
  onSubmit,
  formType,
  submitButton,
  hideMembershipCost,
}) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useFormReturn;

  const tooltipLabel = (
    <Tooltip direction="bottom" trigger={<InformationIcon color="#929292" height="20" width="20" />}>
      <Typo variant="p2" className="!text-p4 max-w-[280px] whitespace-normal  text-gray-100">
        Users who meet the requirement can deposit t.p. to become a Territory member. Only members are allowed to
        publish in the Territory.{' '}
        <Link target="_blank" href={TERRITORY_V1_URL} className="underline">
          Learn more
        </Link>
      </Typo>
    </Tooltip>
  );

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (formType === FormType.EDIT) {
      return;
    }

    const slug = generateTerritoryUrlFromTerritoryName(e.target.value);
    setValue('slug', slug);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="@container">
      {formType === FormType.EDIT && (
        <ImageInput
          defaultValue={getValues('logo')}
          {...register('logo', {
            validate: validateInputImage,
          })}
        />
      )}
      <TextInput
        label="Territory Name*"
        placeholder="TerritoryName"
        {...register('name', {
          required: 'this field is required',
          maxLength: {
            value: MAX_CHARACTERS_IN_TERRITORY_NAME,
            message: `Max ${MAX_CHARACTERS_IN_TERRITORY_NAME} characters allowed`,
          },
          pattern: {
            value: TERRITORY_NAME_REGEX,
            message: TERRITORY_NAME_REGEX_ERROR_MESSAGE,
          },
          onChange: onNameChange,
        })}
        errorMessage={errors?.name?.message}
        maxLength={MAX_CHARACTERS_IN_TERRITORY_NAME}
      />
      <TextInput label="Territory URL" placeholder={EMPTY_TERRITORY_PAGE_URL} readOnly {...register('slug')} />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextArea
            placeholder="What types of posts and prompts should be published in this Territory?"
            label="Description"
            rows={3}
            {...field}
          />
        )}
      />
      {!hideMembershipCost && (
        <TextInput
          label="Required t.p. for membership"
          labelRightAdornment={tooltipLabel}
          placeholder={`${DEFAULT_TERRITORY_MEMBERSHIP_COST}`}
          type="number"
          {...register('territoryMembershipCost', {
            required: true,
            valueAsNumber: true,
            min: {
              value: ROLE_TERRITORY_MEMBERSHIP_CONST_MIN,
              message: `Min value ${ROLE_TERRITORY_MEMBERSHIP_CONST_MIN} allowed`,
            },
            max: {
              value: ROLE_TERRITORY_MEMBERSHIP_CONST_MAX,
              message: `Max value ${ROLE_TERRITORY_MEMBERSHIP_CONST_MAX} allowed`,
            },
          })}
          errorMessage={errors?.territoryMembershipCost?.message}
        />
      )}
      <div className="flex flex-col justify-center gap-8 mt-4">
        {formType === FormType.CREATE && (
          <Typo variant="small-p" className="!text-sm text-gray-400">
            By Launching this Territory you agree to the{' '}
            <Link target="_blank" href={TERRITORY_GUIDELINES_URL} className="underline whitespace-nowrap">
              Admin Code of Conduct
            </Link>
          </Typo>
        )}
        {submitButton}
      </div>
    </Form>
  );
};
