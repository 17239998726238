import { FC, ReactNode } from 'react';
import Typo from '../Typography';

type TInputFeedbackProps = {
  children?: ReactNode;
};

const InputFeedback: FC<TInputFeedbackProps> = ({ children }) => (
  <Typo className="text-red-800" variant="p2">
    {children}
  </Typo>
);

export default InputFeedback;
