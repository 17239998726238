import React, { FC } from 'react';
import cx from 'classnames';
import FooterLink from '../FooterLink/FooterLink';
import { FOOTER_URLS } from '../../../utils/constants';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { AnalyticsFooterItemName } from './constants';

export interface IFooterProps {
  className?: string;
}

const Footer: FC<IFooterProps> = ({ className }) => {
  const { sendEvent } = useAnalytics();

  const FOOTER_LINKS = [
    { analyticsItemName: AnalyticsFooterItemName.home, text: 'Home', href: FOOTER_URLS.HOME },
    { analyticsItemName: AnalyticsFooterItemName.twitter, text: 'Twitter', href: FOOTER_URLS.TWITTER },
    { analyticsItemName: AnalyticsFooterItemName.discord, text: 'Discord', href: FOOTER_URLS.DISCORD },
    { analyticsItemName: AnalyticsFooterItemName.hey, text: 'Hey', href: FOOTER_URLS.HEY },
    { analyticsItemName: AnalyticsFooterItemName.manifesto, text: 'Manifesto', href: FOOTER_URLS.MANIFESTO },
    { analyticsItemName: AnalyticsFooterItemName.docs, text: 'Docs', href: FOOTER_URLS.DOCS },
    {
      analyticsItemName: AnalyticsFooterItemName.termsOfService,
      text: 'Terms of service',
      href: FOOTER_URLS.TERMS_OF_SERVICE,
    },
    {
      analyticsItemName: AnalyticsFooterItemName.privacyNotice,
      text: 'Privacy Notice',
      href: FOOTER_URLS.PRIVACY_NOTICE,
    },
  ];
  return (
    <footer className={cx('w-full pb-10 flex flex-col justify-center items-center', className)}>
      <div className="flex flex-col gap-y-10 md:flex-row items-center md:gap-x-[42px] flex-wrap justify-center">
        {FOOTER_LINKS.map((link) => {
          const onClick = () => sendEvent('footer_item_click', { item_name: link.analyticsItemName });
          return <FooterLink key={link.text} {...link} onClick={onClick} />;
        })}
      </div>
      <p className="mt-14 text-sm font-secondary font-normal text-center text-black-600 md:mt-12">
        © 2021-{new Date().getFullYear()}, All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
