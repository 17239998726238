import React, { FC } from 'react';
import cx from 'classnames';

export type THeroSlideContentWrapperProps = {
  children: React.ReactNode;
  className?: string;
};

const HeroSlideContentWrapper: FC<THeroSlideContentWrapperProps> = ({ children, className }) => (
  <div className={cx('flex flex-col gap-y-7', className)}>{children}</div>
);

export default HeroSlideContentWrapper;
