import { APP_URL } from '../../../config';

export const TERRITORY_V1_URL = 'https://app.t2.world/article/cln0b57su204041xmce3912p48';
export const DEFAULT_TERRITORY_MEMBERSHIP_COST = 5;
export const EMPTY_TERRITORY_PAGE_URL = `${APP_URL}/t/`;
export const TERRITORY_GUIDELINES_URL = 'https://app.t2.world/article/clsumn0yn351491zmcpnjy4gsq';

export enum FormType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}
