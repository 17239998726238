import React, { FC } from 'react';
import cx from 'classnames';
import Typo from '../Typography/Typo';

type TArticleInteractionsCopyProps = {
  children: React.ReactNode;
  className?: string;
};

const ArticleInteractionsCopy: FC<TArticleInteractionsCopyProps> = ({ children, className }) => (
  <Typo variant="small-p" className={cx('text-black-700 group-hover:text-orange-500', className)}>
    {children}
  </Typo>
);

export default ArticleInteractionsCopy;
