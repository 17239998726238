import React from 'react';
import { Article } from 't2-graphql-types';
import { useGetLensPublicationsStats } from '../../services/lens/hooks/useGetLensPublicationsStats';
import {
  getRecommendedPostsQuery,
  getRecommendedPostsWithoutUserRelationsQuery,
} from './queries/getRecommendedPostsQuery';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { ArticleCardPurpose } from '../ArticleCard/constants';
import { PaginatedArticlesList } from '../PaginatedArticlesList/PaginatedArticlesList';
import { useReactiveAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { ArticleType } from '../Article/types';
import { useT2Query } from '../../hooks/useT2Query';
import { PAGE_SIZE } from './constants';

type TRecommendedPostsQueryResponse = {
  listing: {
    name: string;
    articlesCount: number;
    articles: Article[];
  } | null;
};

export const HomepageRecommendedPostsContainer = () => {
  const { sendEvent } = useAnalytics();
  const { authenticatedUserId, loading: authenticatedUserLoading } = useReactiveAuthenticatedUser();
  const { data, loading, error, refetch } = useT2Query<TRecommendedPostsQueryResponse>(
    authenticatedUserId ? getRecommendedPostsQuery : getRecommendedPostsWithoutUserRelationsQuery,
    {
      variables: { skip: 0, take: PAGE_SIZE, userId: authenticatedUserId, type: ArticleType.Article },
      skip: authenticatedUserLoading,
    },
  );
  const articlesCount = data?.listing?.articlesCount || 0;
  const { articlesWithLensStats, lensStatsLoading } = useGetLensPublicationsStats(data?.listing?.articles);

  const onArticleCardClick = (articleId: string) => () => {
    sendEvent('post_card_click', { purpose: ArticleCardPurpose.recommendedPosts, post_id: articleId });
  };

  return (
    <PaginatedArticlesList
      listSection={{
        title: 'Featured Posts',
        loading,
      }}
      content={{
        items: articlesWithLensStats,
        itemCount: articlesCount,
        loading,
        error: !!error,
        refetch,
        onItemClick: onArticleCardClick,
        emptyMessage: 'No featured posts',
      }}
      lens={{ loading: lensStatsLoading }}
      showCounter={false}
      pageSize={PAGE_SIZE}
    />
  );
};
