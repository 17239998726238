import React, { FC } from 'react';

type TPinIconProps = {
  className?: string;
};

export const PinIcon: FC<TPinIconProps> = ({ className }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M5.15781 10.8421L1 15L5.15781 10.8421ZM7.59633 4.24585L6.44906 5.39311C6.3555 5.4867 6.30869 5.53349 6.25538 5.57067C6.20806 5.60367 6.15702 5.63099 6.10331 5.65205C6.0428 5.67579 5.97792 5.68877 5.84814 5.71472L3.15474 6.2534C2.45479 6.39339 2.10482 6.46338 1.94109 6.64791C1.79845 6.80866 1.73331 7.02379 1.76283 7.23665C1.79671 7.48104 2.04907 7.73336 2.55381 8.23817L7.76185 13.4462C8.26658 13.9509 8.51898 14.2033 8.7633 14.2372C8.97623 14.2667 9.19136 14.2015 9.35211 14.0589C9.53659 13.8952 9.60657 13.5452 9.74658 12.8452L10.2853 10.1518C10.3112 10.0221 10.3242 9.95719 10.348 9.8967C10.369 9.84297 10.3963 9.79196 10.4293 9.74463C10.4665 9.69127 10.5133 9.64452 10.6069 9.55096L11.7541 8.40369C11.8139 8.34386 11.8439 8.31394 11.8768 8.28778C11.906 8.26463 11.9369 8.24368 11.9693 8.22516C12.0058 8.20428 12.0446 8.18767 12.1224 8.1543L13.9558 7.36858C14.4907 7.13933 14.7581 7.02475 14.8796 6.83953C14.9858 6.67757 15.0238 6.48022 14.9853 6.29039C14.9413 6.07331 14.7356 5.86757 14.3241 5.4561L10.5439 1.67587C10.1324 1.2644 9.92666 1.05866 9.70961 1.01466C9.51976 0.976172 9.32241 1.01418 9.16049 1.12041C8.97527 1.24189 8.86061 1.50932 8.63144 2.04418L7.84572 3.87754C7.81235 3.95532 7.79566 3.99421 7.77486 4.03067C7.75634 4.06307 7.73539 4.09401 7.71217 4.12322C7.68608 4.15611 7.65616 4.18602 7.59633 4.24585Z"
      fill=""
    />
    <path
      d="M5.15781 10.8421L1 15M7.59633 4.24585L6.44906 5.39311C6.3555 5.4867 6.30869 5.53349 6.25538 5.57067C6.20806 5.60367 6.15702 5.63099 6.10331 5.65205C6.0428 5.67579 5.97792 5.68877 5.84814 5.71472L3.15474 6.2534C2.45479 6.39339 2.10482 6.46338 1.94109 6.64791C1.79845 6.80866 1.73331 7.02379 1.76283 7.23665C1.79671 7.48104 2.04907 7.73336 2.55381 8.23817L7.76185 13.4462C8.26658 13.9509 8.51898 14.2033 8.7633 14.2372C8.97623 14.2667 9.19136 14.2015 9.35211 14.0589C9.53659 13.8952 9.60657 13.5452 9.74658 12.8452L10.2853 10.1518C10.3112 10.0221 10.3242 9.95719 10.348 9.8967C10.369 9.84297 10.3963 9.79196 10.4293 9.74463C10.4665 9.69127 10.5133 9.64452 10.6069 9.55096L11.7541 8.40369C11.8139 8.34386 11.8439 8.31394 11.8768 8.28778C11.906 8.26463 11.9369 8.24368 11.9693 8.22516C12.0058 8.20428 12.0446 8.18767 12.1224 8.1543L13.9558 7.36858C14.4907 7.13933 14.7581 7.02475 14.8796 6.83953C14.9858 6.67757 15.0238 6.48022 14.9853 6.29039C14.9413 6.07331 14.7356 5.86757 14.3241 5.45609L10.5439 1.67587C10.1324 1.2644 9.92666 1.05866 9.70961 1.01466C9.51976 0.976172 9.32241 1.01418 9.16049 1.12041C8.97527 1.24189 8.86061 1.50932 8.63144 2.04418L7.84572 3.87754C7.81235 3.95532 7.79566 3.99421 7.77486 4.03067C7.75634 4.06307 7.73539 4.09401 7.71217 4.12322C7.68608 4.15611 7.65616 4.18602 7.59633 4.24585Z"
      stroke=""
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
