import { TSortingDropdownItems } from '../SortingDropdown/types';

export const enum PromptListPurpose {
  latestPrompts = 'latest_prompts',
  territoryPrompts = 'territory_prompts',
  userPrompts = 'user_prompts',
  searchResultsPrompts = 'search_results_prompts',
}

export enum PromptsSortingDropdownKey {
  Newest = 'Newest',
  Oldest = 'Oldest',
  MostReplies = 'MostReplies',
  RecommendedPrompts = 'RecommendedPrompts',
}

export const PROMPTS_SORTING_DROPDOWN_ITEMS: TSortingDropdownItems[] = [
  {
    name: PromptsSortingDropdownKey.Newest,
    label: 'Newest',
  },
  {
    name: PromptsSortingDropdownKey.Oldest,
    label: 'Oldest',
  },
  {
    name: PromptsSortingDropdownKey.MostReplies,
    label: 'Most Replies',
  },
  {
    name: PromptsSortingDropdownKey.RecommendedPrompts,
    label: 'Recommended',
  },
];
