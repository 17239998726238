import { unsafeCharactersErrorMessage } from '../../../constants/errorMessages';

export const SPECIAL_CHARS_ALLOWED_IN_NAME = ['-', '_', '.', '+', ':'];
export const TERRITORY_NAME_REGEX = new RegExp(`^[a-zA-Z0-9${SPECIAL_CHARS_ALLOWED_IN_NAME.join('')}]*$`);
export const TERRITORY_NAME_INVALID_CHARS_REGEX = new RegExp(
  `[^a-zA-Z0-9${SPECIAL_CHARS_ALLOWED_IN_NAME.join('')}]+`,
  'gm',
);
export const MAX_CHARACTERS_IN_TERRITORY_NAME = 21;
export const TERRITORY_NAME_REGEX_ERROR_MESSAGE = unsafeCharactersErrorMessage('Name', {
  allowUpperCase: true,
  allowedSpecialChars: SPECIAL_CHARS_ALLOWED_IN_NAME,
});
export const TERRITORY_NAME_USED_ERROR_MESSAGE = 'Territory name is already taken';

export const TERRITORY_TAG_COLORS = [
  '#DCEDDC',
  '#F1F1F0',
  '#E3E2E0',
  '#EEE0DB',
  '#FADECB',
  '#FDECCB',
  '#D4E5EE',
  '#E8DEED',
  '#F5E0E9',
  '#FFE2DE',
];
