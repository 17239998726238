import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useModal } from '../../Modal/useModal';
import { ModalType, TTerritoryDetailsContextProps } from '../types';

const TerritoryDetailsContext = createContext<TTerritoryDetailsContextProps>({} as TTerritoryDetailsContextProps);

type TTerritoryDetailsModalContextProviderProps = {
  children: ReactNode;
};

export const TerritoryDetailsModalContextProvider = ({ children }: TTerritoryDetailsModalContextProviderProps) => {
  const [modalType, setModalType] = useState<ModalType | undefined>(undefined);

  const resetTerritoryDetailsModalType = () => setModalType(undefined);

  const { openModal, ModalComponent, closeModal, isOpen } = useModal({
    closeModalCallback: resetTerritoryDetailsModalType,
  });

  const handleOpenShareTerritoryModal = () => {
    setModalType(ModalType.Share);
  };

  const handleOpenLeaveTerritoryModal = () => {
    setModalType(ModalType.LeaveTerritory);
  };

  const handleOpenTerritoryMembersModal = () => {
    setModalType(ModalType.TerritoryMembers);
  };

  useEffect(() => {
    if (modalType) {
      openModal();
    } else {
      closeModal();
    }
  }, [modalType]);

  const value = useMemo(
    () => ({
      openModal,
      isOpen,
      ModalComponent,
      closeModal: resetTerritoryDetailsModalType,
      modalType,
      setModalType,
      handleOpenShareTerritoryModal,
      handleOpenLeaveTerritoryModal,
      handleOpenTerritoryMembersModal,
    }),
    [isOpen, modalType],
  );

  return <TerritoryDetailsContext.Provider value={value}>{children}</TerritoryDetailsContext.Provider>;
};

export const useTerritoryDetailsModalContext = () => useContext(TerritoryDetailsContext);
