import { ReactNode, SyntheticEvent } from 'react';
import HeyLogoIcon from 'src/components/ShareModal/HeyLogoIcon';
import XLogoIncon from 'src/components/ShareModal/XLogoIncon';
import getShareArticleOnLensUrl from 'utils/getShareArticleOnLensUrl';
import getTwitterShareURL from 'utils/getTwitterShareURL';
import { getShareOnWarpcastUrl } from 'utils/getShareOnWarpcastUrl';
import { FarcasterLogoIcon } from 'src/components/Icons/FarcasterLogoIcon';
import ShareIcon from 'src/components/Icons/ShareIcon';

export type TSharePlatformProps = {
  platform: string;
  name: string;
  url?: string;
  icon: ReactNode;
  onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
};

export type TGetSharePlatformProps = {
  url: string;
  useTerritoryText?: boolean;
};

export const getSharePlatformProps = ({
  url,
  useTerritoryText = false,
}: TGetSharePlatformProps): TSharePlatformProps[] => [
  {
    platform: 'x',
    name: 'X',
    url: getTwitterShareURL(url as string, useTerritoryText),
    icon: <XLogoIncon />,
  },
  {
    platform: 'hey',
    name: 'Hey',
    url: getShareArticleOnLensUrl(url as string, useTerritoryText),
    icon: <HeyLogoIcon />,
  },
  {
    platform: 'warpcast',
    name: 'Warpcast',
    url: getShareOnWarpcastUrl({ url }),
    // ambigous group-hover relates to `icon` in `ShareModal.tsx`
    icon: <FarcasterLogoIcon className="group-hover:fill-white-900" />,
  },
  {
    platform: 'native-share',
    name: 'Share',
    url: '',
    onClick: (e) => {
      e.preventDefault();

      navigator?.share?.({
        text: 'Found this good read!',
        url,
      });
    },
    // ambigous group-hover relates to `icon` in `ShareModal.tsx`
    icon: <ShareIcon className="stroke-black-700 group-hover:stroke-white-900" />,
  },
];
