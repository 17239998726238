import { gql } from '@apollo/client';

export const getSortedPromptsQuery = gql`
  query getSortedPrompts($where: ArticleWhereInput, $sortBy: ArticlesOrder, $skip: Int!, $take: Int!) {
    getSortedArticles(where: $where, sortBy: $sortBy, skip: $skip, take: $take) {
      articles {
        id
        type
        title
        commentsCount(where: { deactivated: { equals: false } })
        childArticlesCount(where: { status: { equals: "published" }, deactivated: { equals: false } })
        slug
        territory {
          id
          name
          tagColor
        }
        lensPublicationId
        author {
          id
          name
          username
          profileImage {
            id
            publicUrl
            publicUrlTransformed
            _meta {
              public_id
              height
              width
            }
          }
        }
        promptSettings {
          id
          startDate
          endDate
          liveStatus
        }
        territoryPinnedAt
      }
      articlesCount
    }
  }
`;
