import { useEffect, useState } from 'react';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { PaginationPurpose } from './constants';

type TUsePaginationProps = {
  refetch: Function;
  pageSize: number;
  loading: boolean;
  error: boolean;
  pageResetKey?: string;
  purpose?: PaginationPurpose;
};

type TUsePaginationResult = {
  currentPage: number;
  onPaginationChange: (page: number, pageSize: number) => void;
};

export const usePagination = ({
  refetch,
  pageSize,
  loading,
  error,
  pageResetKey,
  purpose,
}: TUsePaginationProps): TUsePaginationResult => {
  const [currentPage, setCurrentPage] = useState(1);
  const { sendEvent } = useAnalytics();

  useEffect(() => {
    if (pageResetKey) {
      setCurrentPage(1);
    }
  }, [pageResetKey]);

  const onPaginationChange = (page: number) => {
    if (page === currentPage || loading || error) {
      return;
    }

    setCurrentPage(page);

    if (purpose) {
      sendEvent('pagination_click', { purpose });
    }

    refetch({
      skip: (page - 1) * pageSize,
    });
  };

  return {
    currentPage,
    onPaginationChange,
  };
};
