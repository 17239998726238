const ThreeDotsIcon = () => (
  <svg
    width="25"
    height="25"
    className="stroke-black-700 hover:!stroke-orange-500"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9785 13.4258C20.5308 13.4258 20.9785 12.9781 20.9785 12.4258C20.9785 11.8735 20.5308 11.4258 19.9785 11.4258C19.4262 11.4258 18.9785 11.8735 18.9785 12.4258C18.9785 12.9781 19.4262 13.4258 19.9785 13.4258Z"
      stroke=""
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9785 13.4258C13.5308 13.4258 13.9785 12.9781 13.9785 12.4258C13.9785 11.8735 13.5308 11.4258 12.9785 11.4258C12.4262 11.4258 11.9785 11.8735 11.9785 12.4258C11.9785 12.9781 12.4262 13.4258 12.9785 13.4258Z"
      stroke=""
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.97852 13.4258C6.5308 13.4258 6.97852 12.9781 6.97852 12.4258C6.97852 11.8735 6.5308 11.4258 5.97852 11.4258C5.42623 11.4258 4.97852 11.8735 4.97852 12.4258C4.97852 12.9781 5.42623 13.4258 5.97852 13.4258Z"
      stroke=""
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ThreeDotsIcon;
