const ReadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_1329_1301)">
      <path
        d="M18.3337 9.23336V10C18.3326 11.797 17.7507 13.5456 16.6748 14.9849C15.5988 16.4242 14.0864 17.4771 12.3631 17.9866C10.6399 18.4961 8.79804 18.4349 7.11238 17.8122C5.42673 17.1894 3.98754 16.0384 3.00946 14.5309C2.03138 13.0234 1.56682 11.2401 1.68506 9.44696C1.80329 7.65383 2.498 5.94697 3.66556 4.58092C4.83312 3.21488 6.41098 2.26285 8.16382 1.86682C9.91665 1.47079 11.7505 1.65198 13.392 2.38336"
        stroke="#929292"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 3.33331L10 11.675L7.5 9.17498"
        stroke="#929292"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1329_1301">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ReadIcon;
