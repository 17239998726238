import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import isNumber from 'lodash/isNumber';
import Typo from '../Typography';
import InformationIcon from '../Icons/InformationIcon';
import Tooltip from '../Tooltip/Tooltip';
import { colors } from '../Spinner/utils/colors';
import { TToolTipDirection } from '../Tooltip/types';

export type TListSectionTitleProps = {
  text?: string;
  itemsCount?: number;
  className?: string;
  tooltipText?: string;
  tooltipDirection?: TToolTipDirection;
  loading?: boolean;
  sortingDropdown?: ReactNode;
  showCounter?: boolean;
};

export const ListSectionTitle: FC<TListSectionTitleProps> = ({
  text,
  itemsCount,
  className,
  tooltipText,
  tooltipDirection = 'bottom',
  loading,
  sortingDropdown,
  showCounter,
}) => (
  <div className={cx(className, 'flex items-center')}>
    {text && (
      <Typo variant="p1" className="!text-[22px] lg:!text-[28px]">
        {text}{' '}
        <span className="hidden sm:inline">{!loading && showCounter && isNumber(itemsCount) && `(${itemsCount})`}</span>
      </Typo>
    )}
    {tooltipText && (
      <div className="mx-4">
        <Tooltip
          direction={tooltipDirection}
          trigger={
            <div className="w-[20px] h-[20px]">
              <InformationIcon color={colors?.gray[400]} />
            </div>
          }
        >
          {tooltipText}
        </Tooltip>
      </div>
    )}
    {sortingDropdown}
  </div>
);
