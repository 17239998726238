export const articlesQueryFragment = (articlesLabel = 'articles') => `
${articlesLabel} (
  skip: $skip, 
  take: $take, 
  where: { 
    status: { equals: "published" }, 
    deactivated: { equals: false }, 
    type: { equals: $type }
  }, 
  orderBy: { publishedAt: desc }
) {
    ...ArticleCardFields
    userRelations(where: {
      relationType: {
        equals:read
      }, 
      percentReadTime: {
        gt:"0.5"
      }, 
      user: {
        id:{ 
          equals: $userId 
        } 
      } 
    }){
      id
      percentReadTime
      relationType
    }
}`;

export const articlesWithoutUserRelationsQueryFragment = (articlesLabel = 'articles') => `
${articlesLabel} (skip: $skip, take: $take, where: { status: { equals: "published" }, deactivated: { equals: false }, type: { equals: $type }}, orderBy: { publishedAt: desc }) {
    ...ArticleCardFields
}`;
