import { FC } from 'react';
import cx from 'classnames';
import Typo from '../Typography/Typo';
import Tooltip from '../Tooltip/Tooltip';
import { getPromptTooltipLabel } from './utils/getPromptTooltipLabel';
import { PromptSettingsLiveStatus } from '../Prompt/constants';

export type TPromptCardStatusLabelProps = {
  startDate: string;
  endDate: string;
  status: PromptSettingsLiveStatus;
};

export const PromptCardStatusLabel: FC<TPromptCardStatusLabelProps> = ({ startDate, endDate, status }) => (
  <div>
    <Tooltip
      direction="bottom"
      cursor="default"
      trigger={
        <Typo
          variant="small-p"
          className={cx('relative !text-sm font-semibold', {
            'text-t2-success-darkGreen uppercase before:absolute before:top-[6px] before:-left-3 before:w-2 before:h-2 before:rounded-full before:bg-t2-success-darkGreen':
              status === PromptSettingsLiveStatus.LIVE,
            'text-t2-neutral-grey09 capitalize':
              status === PromptSettingsLiveStatus.UPCOMING || status === PromptSettingsLiveStatus.COMPLETED,
          })}
        >
          {getPromptTooltipLabel(status)}
        </Typo>
      }
    >
      <Typo variant="small-p" className="!text-sm text-center">
        {startDate} - {endDate}
      </Typo>
    </Tooltip>
  </div>
);
