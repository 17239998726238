import { gql } from '@apollo/client';

export const getUserProfileCardData = gql`
  query getUserProfileCardData($userId: ID!) {
    user(where: { id: $userId }) {
      id
      name
      username
      bio
      profileImage {
        id
        publicUrl
        publicUrlTransformed
        _meta {
          public_id
          height
          width
        }
      }
      cumulativeTimePoints
      articles(where: { status: { equals: "published" } }) {
        id
        type
      }
      articlesCount: articlesCount(where: { type: { equals: ARTICLE }, status: { equals: "published" } })
      promptsCount: articlesCount(where: { type: { equals: PROMPT }, status: { equals: "published" } })
    }
  }
`;
