import React, { FC } from 'react';
import { THeroSlideProps } from './types';
import Typo from '../Typography';
import LinkButton from '../LinkButton/LinkButton';
import HeroSlideWrapper from './HeroSlideWrapper';
import HeroSlideContentWrapper from './HeroSlideContentWrapper';
import { EOY_LINK } from './constants';

type TEOYSlideProps = THeroSlideProps;

export const EOYSlide: FC<TEOYSlideProps> = ({ onButtonClick }) => (
  <HeroSlideWrapper className="md:!bg-t2-primary-beige relative justify-between px-5 md:px-14 xl:px-24 before:absolute before:inset-0 before:bg-eoy-2024-mobile before:md:bg-eoy-2024-tablet before:xl:bg-eoy-2024-desktop before:rounded-[24px] before:-z-0 before:bg-no-repeat before:bg-cover">
    <HeroSlideContentWrapper className="mt-[180px] md:mt-0 relative items-center sm:items-start md:max-w-[55%] xl:max-w-[60%]">
      <div className="flex flex-col gap-4 justify-center sm:justify-start">
        <Typo
          variant="small-p"
          className="!text-3xl md:!text-5xl xl:!text-6xl !font-primary !font-semibold text-t2-neutral-grey08"
        >
          <span className="!text-4xl md:!text-6xl text-t2-primary-brandColor !italic">2024, what a year!</span> <br />{' '}
          <span className="!text-2xl md:!text-[45px]">here’s a wrap for all of us</span>
        </Typo>
      </div>
      <Typo variant="p2" className="text-left !text-base md:!text-lg text-t2-neutral-grey07">
        This year was monumental for writers, readers, and communities. As we close the chapter, let's celebrate the
        growth, creativity, and friendships we built together.
      </Typo>
      <LinkButton onClick={onButtonClick} variant="secondary" className="!bg-t2-primary-brandColor" href={EOY_LINK}>
        Dive into the full story
      </LinkButton>
    </HeroSlideContentWrapper>
  </HeroSlideWrapper>
);
