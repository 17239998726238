import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ArticleCard, TArticleCardProps } from '../ArticleCard/ArticleCard';
import { getArticlesCardData } from '../ArticleCard/services/getArticlesCardData';
import Typo from '../Typography';
import getHomepagePostsFeed from './queries/getHomepagePostsFeed';
import { useGetLensPublicationsStats } from '../../services/lens/hooks/useGetLensPublicationsStats';
import { ArticleCardLoader } from '../ArticleCard/ArticleCardLoader';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { ArticleCardPurpose } from '../ArticleCard/constants';
import { ARTICLES_SORTING_DROPDOWN_ITEMS, ArticlesSortingDropdownKey } from '../TerritoryArticlesSection/constants';
import { SortingDropdown } from '../SortingDropdown/SortingDropdown';

const NUMBER_OF_ARTICLES = 18;
const DEFAULT_SORT_BY = ArticlesSortingDropdownKey.RecommendedArticles;

const HomepagePostsContainer = () => {
  const { sendEvent } = useAnalytics();
  const [getHomepagePosts, { data, loading, error }] = useLazyQuery(getHomepagePostsFeed, {
    variables: {
      take: NUMBER_OF_ARTICLES,
      type: 'ARTICLE',
      sortBy: DEFAULT_SORT_BY,
    },
  });

  useEffect(() => {
    getHomepagePosts();
  }, []);

  const onSortByChange = (sortBy: string) => {
    getHomepagePosts({
      variables: {
        sortBy,
      },
    });
  };

  const { articlesWithLensStats, lensStatsLoading } = useGetLensPublicationsStats(data?.getHomepagePostsFeed);

  const renderArticleCardLoaders = () =>
    // eslint-disable-next-line react/no-array-index-key
    Array.from({ length: NUMBER_OF_ARTICLES }, (_, index) => <ArticleCardLoader key={index} />);

  if (error) {
    return null;
  }

  return (
    <>
      <div className="flex mb-8 lg:mb-11 items-center gap-4">
        <Typo variant="p1" className="!text-[22px] lg:!text-[28px]">
          Posts
        </Typo>
        <SortingDropdown
          items={ARTICLES_SORTING_DROPDOWN_ITEMS}
          onSortByChange={onSortByChange}
          purpose="homepage_articles"
          defaultSortBy={DEFAULT_SORT_BY}
        />
      </div>
      <div>
        {loading
          ? renderArticleCardLoaders()
          : getArticlesCardData(articlesWithLensStats || [], false).map((article: TArticleCardProps) => {
              const onArticleCardClick = () => {
                sendEvent('post_card_click', { post_id: article.id, purpose: ArticleCardPurpose.latestPosts });
              };
              return (
                <ArticleCard
                  {...article}
                  key={article.id}
                  lensStatsLoading={lensStatsLoading}
                  onClick={onArticleCardClick}
                  showTerritoryLabel
                />
              );
            })}
      </div>
    </>
  );
};

export default HomepagePostsContainer;
