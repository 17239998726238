import cx from 'classnames';
import { Placeholder } from '../Placeholder/Placeholder';
import { ARTICLE_CARD_CLASSNAMES } from './constants';

const AuthorImagePlaceholder = () => <Placeholder className="w-[18px] h-[18px] rounded-full" />;

const AuthorTextPlaceholder = () => <Placeholder className="w-[95px] h-[18px] rounded-[4px]" />;

const ArticleTitlePlaceholder = () => (
  <Placeholder className="h-[1rem] md:h-[1.5rem] md:mb-[0.5rem] w-[100%] rounded-[4px]" />
);

const ArticleDescrtiptionTextPlaceholer = ({ className = '' }) => (
  <Placeholder className={cx('hidden h-[1.125rem] mb-[0.625rem] rounded-[4px]', className)} />
);

const ArticleImagePlaceholder = () => <Placeholder className="relative w-full h-full flex-shrink-0" />;

export const ArticleCardLoader = () => (
  <div className="@container">
    <div className={cx(ARTICLE_CARD_CLASSNAMES.container, 'mt-2')}>
      <div className="flex @2xl:mt-11 relative -z-10 w-full @2xl:w-[230px] h-[188px] @2xl:min-h-[230px] rounded-t-xl @2xl:rounded-md flex-shrink-0 left-0 overflow-hidden order-1 @2xl:order-2">
        <ArticleImagePlaceholder />
      </div>
      <div className={ARTICLE_CARD_CLASSNAMES.content}>
        <div className={ARTICLE_CARD_CLASSNAMES.authorSectionContainer}>
          <AuthorImagePlaceholder />
          <AuthorTextPlaceholder />
        </div>
        <div className={ARTICLE_CARD_CLASSNAMES.textColumn}>
          <ArticleTitlePlaceholder />
          <ArticleDescrtiptionTextPlaceholer className="md:block w-[100%]" />
          <ArticleDescrtiptionTextPlaceholer className="lg:block w-[75%] " />
        </div>
        <Placeholder className="mt-auto rounded-[4px] h-[1rem] w-[200px]" />
      </div>
    </div>
  </div>
);
