import { format, parseISO } from 'date-fns';

export const formatPromptSettingsDate = (date: string | null) => {
  if (!date) return null;
  const parsedDate = parseISO(date);
  const formattedDate = format(parsedDate, 'MMM d h:mma');
  const [month, day, time] = formattedDate.split(' ');
  const ampm = time.slice(-2).toLowerCase();
  const formattedTime = time.slice(0, -2);

  return `${month.charAt(0).toUpperCase()}${month.slice(1)} ${day}, ${formattedTime} ${ampm} `;
};
