import { FunctionComponent } from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';
import cx from 'classnames';
import { hoverAnimationClassNames } from '../../../utils/hoverAnimationClassNames';
import { transitionAnimationClassNames } from '../../../utils/transitionAnimationClassNames';
import Typo from '../Typography/Typo';
import getManageArticleUrl from './services/getManageArticleUrl';
import CloudinaryResponsiveImage from '../CloudinaryResponsiveImage/CloudinaryResponsiveImage';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import { ARTICLE_CARD_CLASSNAMES, DEFAULT_ARTICLE_IMAGE } from './constants';
import { getMultilineEllipsisStyle } from '../PromptCard/utils/getMultilineEllipsisStyle';
import ReadIcon from './Icons/ReadIcon';
import { UserDetail } from '../UserDetail/UserDetail';
import { ArticleCardDetails } from './ArticleCardDetails';
import Label from '../Label/Label';
import { TArticleCardTerritoryProps } from './types';
import { PinIcon } from '../Icons/PinIcon';
import Dropdown from '../Dropdown/Dropdown';
import ThreeDotsIcon from '../Icons/ThreeDotsIcon';
import { CardDropdownMenu } from '../CardDropdownMenu/CardDropdownMenu';

// TODO: Move to types.ts file to avoid importing redundant code from ArticleCard (better code splitting)
export type TArticleCardProps = {
  id: string;
  authorProfileImage?: TCloudinaryImageShape;
  title: string;
  shortDescription: string;
  url: string;
  slug: string;
  image?: string;
  authorLabel: string;
  authorProfileURL: string;
  coverImage: TCloudinaryImageShape;
  isCurrentlyLoggedInUser?: boolean;
  createdAt: string;
  publishedAt?: string;
  totalTimePointsAccumulated: string;
  commentsCount: number;
  hasRead?: boolean;
  lensPublicationId?: string | null;
  lensStatsLoading?: boolean;
  onClick?: () => void;
  readTime: string;
  territory: TArticleCardTerritoryProps;
  showTerritoryLabel?: boolean;
  territoryPinnedAt?: string;
  canManagePinnedArticles?: boolean;
  onPinClick?: () => void;
  isPinningEnabled?: boolean;
  onDropdownStageChange?: () => void;
};

export const ArticleCard: FunctionComponent<TArticleCardProps> = ({
  authorProfileImage,
  authorLabel,
  authorProfileURL,
  title,
  shortDescription,
  url,
  coverImage,
  createdAt,
  publishedAt,
  id,
  totalTimePointsAccumulated,
  isCurrentlyLoggedInUser = false,
  commentsCount,
  hasRead = false,
  lensStatsLoading,
  onClick,
  territory,
  showTerritoryLabel = false,
  readTime,
  territoryPinnedAt,
  canManagePinnedArticles,
  onPinClick,
  isPinningEnabled,
  onDropdownStageChange,
}) => {
  const territoryName = territory?.name;
  const territoryTagColor = territory?.tagColor as string;
  const dropdownMenuButtons = [
    {
      key: 'pin',
      text: territoryPinnedAt ? 'Unpin Post' : 'Pin Post',
      icon: <PinIcon className="w-4 h-4 self-center fill-none stroke-t2-neutral-grey09" />,
      onClick: () => {
        onPinClick?.();
      },
    },
  ];

  return (
    <div className="@container relative flex flex-col justify-between">
      <div
        className={cx(
          ARTICLE_CARD_CLASSNAMES.container,
          'cursor-pointer hover:shadow-hoverShadow hover:-translate-y-[2px]',
          {
            '!bg-gray-100 border border-gray-400': hasRead,
          },
          hoverAnimationClassNames,
        )}
      >
        <Link onClick={onClick} className="absolute inset-0 w-full z-0" href={url}>
          {' '}
        </Link>
        {isCurrentlyLoggedInUser && (
          <Link href={getManageArticleUrl(id)} legacyBehavior>
            <span
              className={cx(
                'absolute right-5 top-5 @2xl:top-8 @2xl:right-8 !text-p2-m text-orange-500 font-secondary hover:underline hover:underline-offset-2 z-[1]',
                transitionAnimationClassNames,
              )}
            >
              Edit
            </span>
          </Link>
        )}
        <div
          className={cx(
            'flex w-full rounded-t-xl flex-shrink-0 left-0 overflow-hidden order-1',
            '@2xl:w-min @2xl:order-2 @2xl:flex-col @2xl:rounded-none',
          )}
        >
          <div className={cx('hidden min-h-[30px] @2xl:flex @2xl:mt-1 items-center self-end justify-end gap-x-4')}>
            {showTerritoryLabel && territoryName && (
              <Label
                backgroundColor="secondary"
                className={cx({ 'mr-12 -z-10': isCurrentlyLoggedInUser })}
                style={{ backgroundColor: territoryTagColor }}
              >
                t/{territoryName}
              </Label>
            )}
            {hasRead && (
              <div className="flex gap-x-2 items-center -z-10">
                <ReadIcon />
                <Typo variant="p2" className="!text-p4 text-gray-400">
                  Read
                </Typo>
              </div>
            )}
            {isPinningEnabled && territoryPinnedAt && (
              <PinIcon className="stroke-t2-primary-brandColor fill-t2-primary-brandColor -z-10" />
            )}
            {canManagePinnedArticles && (
              <div className="z-30 h-6">
                <Dropdown
                  onStateChange={onDropdownStageChange}
                  label={<ThreeDotsIcon />}
                  menu={<CardDropdownMenu buttons={dropdownMenuButtons} />}
                  position="right"
                  menuContainerClassName="top-[35px] border border-t2-neutral-grey02 shadow-hoverShadow"
                />
              </div>
            )}
          </div>
          <div
            className={cx(
              'flex relative -z-10 w-full h-[188px] rounded-t-xl flex-shrink-0 left-0 overflow-hidden',
              '@2xl:mt-10 @2xl:w-[230px] @2xl:min-h-[230px] @2xl:rounded-md',
            )}
          >
            {showTerritoryLabel && territoryName && (
              <div className="@2xl:hidden absolute bottom-4 right-4 z-10">
                <Label backgroundColor="secondary" style={{ backgroundColor: territoryTagColor }}>
                  t/{territoryName}
                </Label>
              </div>
            )}
            {coverImage ? (
              <CloudinaryResponsiveImage
                title={title}
                aspectRatio={1}
                image={coverImage}
                alt="article image"
                className="absolute object-cover h-[100%] w-[100%] -z-10"
                containerClassName="w-[100%]"
              />
            ) : (
              <Image
                className="-z-10"
                priority
                src={DEFAULT_ARTICLE_IMAGE}
                alt="article image"
                layout="fill"
                objectFit="cover"
              />
            )}
          </div>
        </div>

        <div className={cx(ARTICLE_CARD_CLASSNAMES.content)}>
          <div className={cx(ARTICLE_CARD_CLASSNAMES.authorSectionContainer, 'flex justify-between')}>
            <UserDetail displayName={authorLabel} href={authorProfileURL} profileImage={authorProfileImage} />
            <div className="flex items-center gap-4 @2xl:hidden">
              {hasRead && <ReadIcon />}
              {isPinningEnabled && territoryPinnedAt && (
                <PinIcon className="stroke-t2-primary-brandColor fill-t2-primary-brandColor -z-10" />
              )}
              {canManagePinnedArticles && (
                <div className="z-30 h-6">
                  <Dropdown
                    onStateChange={onDropdownStageChange}
                    label={<ThreeDotsIcon />}
                    menu={<CardDropdownMenu buttons={dropdownMenuButtons} />}
                    position="right"
                    menuContainerClassName="top-[35px] border border-t2-neutral-grey02 shadow-hoverShadow"
                  />
                </div>
              )}
            </div>
          </div>
          <div className={cx(ARTICLE_CARD_CLASSNAMES.textColumn)}>
            <Typo
              variant="p1"
              text={title}
              className={cx('!text-2xl cursor-pointer text-black-700 !font-semibold multiline-ellipsis h-[64px]', {
                '!text-gray-400': hasRead,
              })}
              style={getMultilineEllipsisStyle(2)}
            />
            <Typo
              variant="p2"
              text={shortDescription}
              className={cx('!text-base text-black-700 cursor-pointer multiline-ellipsis', {
                '!text-gray-400': hasRead,
              })}
              style={getMultilineEllipsisStyle(3)}
            />
          </div>
          <ArticleCardDetails
            url={url}
            hasRead={hasRead}
            totalTimePointsAccumulated={totalTimePointsAccumulated}
            commentsCount={commentsCount}
            readTime={readTime}
            publishedAt={publishedAt}
            createdAt={createdAt}
            lensStatsLoading={lensStatsLoading}
          />
        </div>
      </div>
    </div>
  );
};
