import React, { FC } from 'react';
import Typo from '../Typography/Typo';
import { ProfileCardDetailsElement } from './ProfileCardDetailsElement';
import { T2ProfileCardDetailsElementType } from './types';

type TProfileCardDetailsProps = {
  timePoints?: number;
  articleCount?: number;
  promtCount?: number;
};

export const T2ProfileCardDetails: FC<TProfileCardDetailsProps> = ({ timePoints, articleCount, promtCount }) => {
  const profileCardItems = [
    {
      key: T2ProfileCardDetailsElementType.TimePoints,
      element: (
        <Typo variant="small-p" className="!text-sm text-t2-primary-brandColor">
          {Math.floor(timePoints as number) || 0} t.p.
        </Typo>
      ),
    },
    {
      key: T2ProfileCardDetailsElementType.ArticleCount,
      element: (
        <Typo variant="small-p" className="!text-sm text-t2-neutral-grey09">
          {articleCount || 0} <span className="text-t2-neutral-grey05">Posts</span>
        </Typo>
      ),
    },
    {
      key: T2ProfileCardDetailsElementType.PromtCount,
      element: (
        <Typo variant="small-p" className="!text-sm text-t2-neutral-grey09">
          {promtCount || 0} <span className="text-t2-neutral-grey05">Prompts</span>
        </Typo>
      ),
    },
  ];

  return (
    <div className="flex flex-wrap gap-2 items-center">
      {profileCardItems.map((item, index) => {
        const isFirst = index === 0;
        return (
          <ProfileCardDetailsElement key={item.key} isFirst={isFirst}>
            {item.element}
          </ProfileCardDetailsElement>
        );
      })}
    </div>
  );
};
