import { TGetModalComponent } from '../Modal/useModal';

export const enum ModalType {
  Share = 'share',
  LeaveTerritory = 'leave_territory',
  TerritoryMembers = 'territory_members',
}

export type TTerritoryDetailsContextProps = {
  openModal: () => void;
  isOpen: boolean;
  ModalComponent: TGetModalComponent;
  closeModal: () => void;
  modalType: ModalType | undefined;
  setModalType: (type: ModalType | undefined) => void;
  handleOpenShareTerritoryModal: () => void;
  handleOpenLeaveTerritoryModal: () => void;
  handleOpenTerritoryMembersModal: () => void;
};
