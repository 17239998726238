import React, { FC, useState } from 'react';
import Link from 'next/link';
import Typo from '../Typography/Typo';
import { getMultilineEllipsisStyle } from '../PromptCard/utils/getMultilineEllipsisStyle';
import TerritoryMembers from '../TerritoryHeader/TerritoryMembers';
import { TMember } from '../TerritoryMembersPopUp/MemberProfile';
import { formatNumber } from '../../../utils/formatNumber';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { TerritoryLogo } from '../TerritoryHeader/TerritoryLogo';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';

export type TTerritoryCardProps = {
  id: string;
  name: string;
  description: string;
  logo?: TCloudinaryImageShape;
  url: string;
  totalTimePoints: number;
  members?: TMember[];
  membersCount: number;
  isMember?: boolean;
  isLoading?: boolean;
};

export const TerritoryCard: FC<TTerritoryCardProps> = ({
  id,
  name,
  totalTimePoints,
  description,
  logo,
  members = [],
  membersCount,
  url,
  isMember,
  isLoading,
}) => {
  const { sendEvent } = useAnalytics();
  const [isHovered, setIsHovered] = useState(false);

  const onMouseOverCapture = () => {
    setIsHovered(true);
    sendEvent('territory_card_hover', { territory_id: id, territory_name: name });
  };

  const onMouseOutCapture = () => {
    setIsHovered(false);
  };

  const onClick = () => {
    sendEvent('territory_card_click', { territory_id: id, territory_name: name });
  };

  return (
    <div
      onMouseOverCapture={onMouseOverCapture}
      onMouseOutCapture={onMouseOutCapture}
      className="relative group w-52 h-60 md:w-60 px-4 py-5 bg-white-900 rounded-lg"
    >
      <Link onClick={onClick} href={url} className="absolute inset-0 w-full h-full z-10">
        {' '}
      </Link>
      <div className="relative group-hover:hidden h-full flex flex-col gap-y-4 items-center justify-end">
        {isMember && (
          <Typo variant="small-p" className="absolute right-0 top-0 !text-xs text-green-600">
            Member
          </Typo>
        )}
        <div className="w-[100px] h-[100px]">
          <TerritoryLogo logo={logo} isLoading={isLoading} />
        </div>
        <div className="flex flex-col gap-y-1 text-center">
          <Typo variant="p2" className="!text-base md:!text-xl">
            t/{name}
          </Typo>
          <Typo variant="small-p">{formatNumber(totalTimePoints)} t.p.</Typo>
        </div>
      </div>
      <div className="hidden group-hover:flex h-full flex-col justify-between">
        {description && (
          <Typo
            variant="small-p"
            className="!text-sm break-words multiline-ellipsis"
            style={getMultilineEllipsisStyle(7)}
          >
            {description}
          </Typo>
        )}
        <TerritoryMembers
          members={members}
          membersCount={membersCount}
          interactionsCopyClassname="group-hover:!text-black-700 !text-sm"
          avatarClassname="!w-6 !h-6"
          isVisible={isHovered}
        />
      </div>
    </div>
  );
};
