import React, { FC } from 'react';
import Slider, { Settings } from 'react-slick';
import getFp from 'lodash/fp/get';
import { TerritoryCard } from './TerritoryCard';
import { PreviousArrow } from '../TerritoryMembersPopUp/PreviousArrow';
import { NextArrow } from '../TerritoryMembersPopUp/NextArrow';
import { breakpoint } from './utils/breakpoint';
import { TNormalizedTerritory } from '../Territory/types';

const CAROUSEL_PROPS: Settings = {
  infinite: false,
  rows: 1,
  prevArrow: <PreviousArrow />,
  nextArrow: <NextArrow />,
  slidesToShow: 4,
  slidesToScroll: 2,
  className: 'w-[240px]',
  variableWidth: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: parseInt(breakpoint.lg, 10),
      settings: {
        slidesToShow: 3,
        swipe: true,
        variableWidth: true,
        className: 'mx-6',
      },
    },
    {
      breakpoint: parseInt(breakpoint.md, 10),
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        swipe: true,
        variableWidth: true,
      },
    },
    {
      breakpoint: parseInt(breakpoint.sm, 10),
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        swipe: true,
        variableWidth: false,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        centerPadding: '30px',
        centerMode: true,
        className: 'mx-auto w-[300px]',
        variableWidth: false,
      },
    },
    {
      breakpoint: parseInt(breakpoint.xs, 10),
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        centerPadding: '30px',
        className: 'mx-auto w-[280px] territory-slick',
        variableWidth: true,
      },
    },
  ],
};

export type TTerritorySliderProps = {
  territories: TNormalizedTerritory[];
  isLoading?: boolean;
};

const TerritorySlider: FC<TTerritorySliderProps> = ({ territories, isLoading }) => (
  <div className="mt-14 mx-5">
    <Slider {...CAROUSEL_PROPS} className="mx-auto">
      {territories.map((territory) => (
        <div className="md:!flex justify-center" key={territory.id}>
          <TerritoryCard
            {...territory}
            members={territory?.memberships?.map(getFp('user'))}
            membersCount={territory?.membershipsCount}
            isLoading={isLoading}
          />
        </div>
      ))}
    </Slider>
  </div>
);

export default TerritorySlider;
