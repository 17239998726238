import React, { FC } from 'react';
import Image from 'next/image';
import cx from 'classnames';
import RcPagination from 'rc-pagination';
import { usePagination } from './usePagination';
import { PaginationPurpose } from './constants';

type TPaginationProps = {
  pageSize: number;
  totalItems: number;
  refetch: Function;
  loading: boolean;
  error: boolean;
  pageResetKey?: string;
  purpose?: PaginationPurpose;
};
export const Pagination: FC<TPaginationProps> = ({
  pageSize,
  totalItems,
  refetch,
  loading,
  error,
  pageResetKey,
  purpose,
}) => {
  const { currentPage, onPaginationChange } = usePagination({
    refetch,
    pageSize,
    loading,
    error,
    pageResetKey,
    purpose,
  });

  if (totalItems <= pageSize) {
    return null;
  }

  const paginationItemRender = (
    current: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    element: any,
  ) => {
    if (type === 'page') {
      return (
        <div
          className={cx('flex justify-center items-center w-[24px] h-[24px] p-4', {
            'rounded-[32px] bg-white-900': current === currentPage,
          })}
        >
          <button>{current}</button>
        </div>
      );
    }

    if (type === 'next' && currentPage !== Math.ceil(totalItems / pageSize)) {
      return (
        <Image
          className="cursor-pointer"
          width="20"
          height="20"
          alt="next page"
          src="/images/pagination/arrow-right.svg"
        />
      );
    }

    if (type === 'prev' && currentPage !== 1) {
      return (
        <Image
          className="cursor-pointer"
          width="20"
          height="20"
          alt="previous page"
          src="/images/pagination/arrow-left.svg"
        />
      );
    }

    if (type === 'jump-next' || type === 'jump-prev') {
      return <button className="p-1">...</button>;
    }

    return element;
  };

  return (
    <RcPagination
      defaultCurrent={currentPage}
      onChange={onPaginationChange}
      pageSize={pageSize}
      total={totalItems}
      showPrevNextJumpers
      itemRender={paginationItemRender}
      className="flex justify-end items-center mt-8"
    />
  );
};
