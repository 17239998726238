import { gql } from '@apollo/client';

export const getMutualTerritories = gql`
  query mutualTerritories($id1: ID!, $id2: ID!) {
    territories(
      where: {
        AND: [
          { roles: { some: { memberships: { some: { user: { id: { equals: $id1 } }, status: { equals: ACTIVE } } } } } }
          { roles: { some: { memberships: { some: { user: { id: { equals: $id2 } }, status: { equals: ACTIVE } } } } } }
        ]
      }
    ) {
      id
      name
      slug
      logo {
        id
        publicUrl
        publicUrlTransformed
      }
    }
  }
`;
