import { MAX_FILE_SIZE_ALLOWED_IN_BYTES } from '../src/constants';

const isValidFileSize = (file: File) => {
  if (file?.size) {
    return file?.size <= MAX_FILE_SIZE_ALLOWED_IN_BYTES;
  }
  return false;
};

export default isValidFileSize;
