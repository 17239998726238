import { FC } from 'react';
import Image from 'next/image';
import { isUndefined } from 'lodash';
import { Maybe } from 't2-graphql-types';
import { DEFAULT_TERRITORY_LOGO } from './constants';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import { Placeholder } from '../Placeholder/Placeholder';

type TTerritoryLogoProps = {
  logo?: Maybe<TCloudinaryImageShape>;
  isLoading?: boolean;
};

export const TerritoryLogo: FC<TTerritoryLogoProps> = ({ logo, isLoading }) => {
  if (!isLoading && !isUndefined(logo)) {
    return (
      <Image
        width={147}
        height={147}
        src={logo?.publicUrlTransformed || DEFAULT_TERRITORY_LOGO}
        alt="territory logo"
        className="object-cover rounded-full h-full w-full"
      />
    );
  }
  return <Placeholder className="h-full w-full rounded-full" />;
};
