import Link from 'next/link';
import cx from 'classnames';
import { getLinkRelAttributeValue } from 'utils/getLinkRelAttributeValue';
import Typo from '../Typography';
import UserProfileImage from '../UserProfileImage/UserProfileImage';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import { getMultilineEllipsisStyle } from '../PromptCard/utils/getMultilineEllipsisStyle';
import { TAvatarSize } from '../UserProfileImage/types';
import { TUserDetailTextSize } from './types';

const TEXT_SIZE_VARIANT: Record<TUserDetailTextSize, string> = {
  small: '!text-sm',
  medium: '!text-sm md:!text-base',
};

export interface IAuthorProps {
  displayName: string;
  profileImage?: TCloudinaryImageShape;
  size?: TAvatarSize;
  href: string;
  shouldDisplayNameOnDesktopDevices?: boolean;
  textSize?: TUserDetailTextSize;
  onClick?: () => void;
  displayNameClassName?: string;
}

export const UserDetail: React.FC<IAuthorProps> = ({
  displayName,
  profileImage,
  href,
  size,
  shouldDisplayNameOnDesktopDevices,
  textSize = 'medium',
  onClick,
  displayNameClassName,
}) => (
  <Link href={href} legacyBehavior>
    <a
      onClick={onClick}
      target="_blank"
      rel={getLinkRelAttributeValue(href)}
      className="group/user flex items-center cursor-pointer"
    >
      <div className="flex self-center group-hover/user:opacity-[0.8]">
        <UserProfileImage profileImage={profileImage} size={size} />
      </div>
      <div
        className={cx('flex flex-col justify-center group-hover/user:opacity-[0.8]', {
          'hidden xl:block': shouldDisplayNameOnDesktopDevices,
        })}
      >
        <Typo
          variant="p2"
          className={cx('w-fit my-1 ml-5 text-gray-700 multiline-ellipsis group-hover/user:text-orange-500', [
            TEXT_SIZE_VARIANT[textSize],
            displayNameClassName,
          ])}
          style={getMultilineEllipsisStyle(1)}
        >
          {displayName}
        </Typo>
      </div>
    </a>
  </Link>
);
