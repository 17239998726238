import { FC, ReactNode } from 'react';
import cx from 'classnames';
import Typo from '../Typography';
import InputFeedback from '../InputFeedback/InputFeedback';

type TFieldsetProps = {
  children: ReactNode;
  label?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string;
  labelRightAdornment?: ReactNode;
  readOnly?: boolean;
};

const Fieldset: FC<TFieldsetProps> = ({
  children,
  label,
  className,
  disabled,
  errorMessage,
  required,
  labelRightAdornment,
}) => (
  <div className={className}>
    <div className="flex flex-col relative">
      {children}

      {label && (
        <Typo
          className={cx('absolute bg-white-900 left-[16px] top-[20px]', {
            'text-t2-neutral-grey05': disabled,
          })}
          variant="label"
          text={
            <span className="flex items gap-3">
              {label}
              {required ? '*' : ''} <span>{labelRightAdornment && labelRightAdornment}</span>
            </span>
          }
        />
      )}
    </div>
    {errorMessage && <InputFeedback>{`*${errorMessage}`}</InputFeedback>}
  </div>
);

export default Fieldset;
