export const enum PromptSettingsLiveStatus {
  COMPLETED = 'COMPLETED',
  UPCOMING = 'UPCOMING',
  LIVE = 'LIVE',
}

export enum PromptTypePicker {
  STANDARD = 'STANDARD',
  LIVE = 'LIVE',
}
