import { useForm } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import Button from '../Button';
import { useModal } from '../Modal/useModal';
import { CreateTerritoryForm } from '../CreateTerritory/CreateTerritoryForm';
import { getFeatureFlag } from '../FeatureFlags/getFeatureFlag';
import { useReactiveHasPermission } from '../Permissions/hooks/useHasPermission';
import { DEFAULT_TERRITORY_MEMBERSHIP_COST } from '../TerritoryForm/constants';
import { CloseConfirmationModal } from '../ConfirmationModal/CloseConfirmationModal';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { AnalyticsModalType } from '../Modal/constants';
import ProtectWithLogin from '../ProtectWithLogin/ProtectWithLogin';

type THomepageCreateTerritorySectionProps = {
  isUserAuthenticated: boolean;
};

export const HomepageCreateTerritorySection = ({ isUserAuthenticated }: THomepageCreateTerritorySectionProps) => {
  const hasPermission = useReactiveHasPermission();
  const hasPermissionToCreateTerritory =
    hasPermission('GLOBAL_CAN_CREATE_TERRITORY') || hasPermission('GLOBAL_CAN_CREATE_CUSTOM_TERRITORY');
  const { sendEvent } = useAnalytics();
  const showCreateTerritorySection =
    getFeatureFlag('userGeneratedTerritories') && (!isUserAuthenticated || hasPermissionToCreateTerritory);

  const defaultValues = {
    name: '',
    slug: '',
    description: '',
    logo: '',
    territoryMembershipCost: getFeatureFlag('createNewTerritoryAsV2') ? 0 : DEFAULT_TERRITORY_MEMBERSHIP_COST,
  };

  const useFormReturn = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const onCloseTerritoryModal = () => {
    const {
      getValues,
      formState: { defaultValues, isSubmitting },
    } = useFormReturn;

    if (!isSubmitting && !isEqual(getValues(), defaultValues)) {
      openTerritoryConfirmationModal();
    }
  };

  const openCreateTerritoryModalWithAnalytics = () => {
    sendEvent('new_territory_button_click');
    openCreateTerritoryModal();
  };

  const closeCreateTerritoryModalWithAnalytics = () => {
    sendEvent('new_territory_abandon');
    closeCreateTerritoryModal();
  };

  const closeTerritoryConfirmationModalWithAnalytics = () => {
    sendEvent('new_territory_abandon');
    closeTerritoryConfirmationModal();
  };

  const {
    openModal: openCreateTerritoryModal,
    closeModal: closeCreateTerritoryModal,
    ModalComponent: CreateTerritoryModal,
  } = useModal({
    closeModalCallback: onCloseTerritoryModal,
    analytics: { modalName: AnalyticsModalType.newTerritory },
  });

  const {
    openModal: openTerritoryConfirmationModal,
    closeModal: closeTerritoryConfirmationModal,
    ModalComponent: TerritoryConfirmationModalComponent,
  } = useModal({ analytics: { modalName: AnalyticsModalType.abandonTerritory } });

  if (!showCreateTerritorySection) {
    return null;
  }

  return (
    <>
      <div>
        <CreateTerritoryModal title="Launch a new Territory">
          <CreateTerritoryForm useFormReturn={useFormReturn} closeModal={closeCreateTerritoryModalWithAnalytics} />
        </CreateTerritoryModal>
        <ProtectWithLogin>
          <Button
            variant="primary"
            rounded
            onClick={openCreateTerritoryModalWithAnalytics}
            className="flex justify-center md:min-w-[190px]"
          >
            <span className="flex items-center before:content-plus before:h-[20px] before:mr-2 whitespace-nowrap">
              New Territory
            </span>
          </Button>
        </ProtectWithLogin>
      </div>
      <CloseConfirmationModal
        ModalComponent={TerritoryConfirmationModalComponent}
        closeConfirmationModal={closeTerritoryConfirmationModalWithAnalytics}
        closeModal={closeCreateTerritoryModal}
        openModal={openCreateTerritoryModal}
      />
    </>
  );
};
