import cx from 'classnames';
import { ARTICLE_CARD_CLASSNAMES } from '../ArticleCard/constants';
import { Placeholder } from '../Placeholder/Placeholder';

const AuthorImagePlaceholder = () => <Placeholder className="w-[18px] h-[18px] rounded-full" />;

const AuthorTextPlaceholder = () => <Placeholder className="w-[95px] h-[18px] rounded-[4px]" />;

const PromptTitlePlaceholder = () => (
  <Placeholder className="h-[1rem] md:h-[1.5rem] md:mb-[0.5rem] w-[100%] rounded-[4px]" />
);

const PromptDescrtiptionTextPlaceholer = ({ className = '' }) => (
  <Placeholder className={cx('hidden h-[1.125rem] mb-[0.625rem] rounded-[4px]', className)} />
);

export const PromptCardLoader = () => (
  <div className="@container">
    <div className={cx(ARTICLE_CARD_CLASSNAMES.container, 'pt-6')}>
      <div className={ARTICLE_CARD_CLASSNAMES.content}>
        <div className={ARTICLE_CARD_CLASSNAMES.authorSectionContainer}>
          <AuthorImagePlaceholder />
          <AuthorTextPlaceholder />
        </div>
        <div className={ARTICLE_CARD_CLASSNAMES.textColumn}>
          <PromptTitlePlaceholder />
          <PromptDescrtiptionTextPlaceholer className="md:block w-[100%]" />
          <PromptDescrtiptionTextPlaceholer className="lg:block w-[75%] " />
        </div>
        <Placeholder className="mt-auto rounded-[4px] h-[1rem] w-[200px]" />
      </div>
    </div>
  </div>
);
