import { gql } from '@apollo/client';

export const createCustomTerritory = gql`
  mutation createCustomTerritory($name: String!, $description: String) {
    createCustomTerritory(name: $name, description: $description) {
      id
      slug
      name
    }
  }
`;
