import { MutationHookOptions, useMutation } from '@apollo/client';
import { Article, ArticleWhereUniqueInput, ArticleUpdateInput } from 't2-graphql-types';
import { updateArticleMutation } from '../mutations/updateArticleById';

type TVariables = { where: ArticleWhereUniqueInput; data: ArticleUpdateInput };

type TData = {
  updateArticle: Article;
};

export const useUpdateArticleMutation = (options?: MutationHookOptions<TData, TVariables>) =>
  useMutation<TData, TVariables>(updateArticleMutation, options);
