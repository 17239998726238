import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import DotSeparator from '../CommentsSection/Comment/DotSeparator';

type TProfileCardDetailsElementProps = {
  children: ReactNode;
  isFirst?: boolean;
};

export const ProfileCardDetailsElement: FC<TProfileCardDetailsElementProps> = ({ children, isFirst }) => (
  <>
    <div
      className={cx({
        hidden: isFirst,
      })}
    >
      <DotSeparator />
    </div>
    <div>{children}</div>
  </>
);
