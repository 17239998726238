import React, { FC } from 'react';
import DotSeparator from '../CommentsSection/Comment/DotSeparator';
import Typo from '../Typography/Typo';
import CommentIcon from '../CommentsSection/Icons/CommentIcon';

type TPromptDetailsProps = {
  postsCount: number;
  commentsCount: number;
  territoryTitle?: string;
  territoryTagColor?: string;
  lensStatsLoading?: boolean;
};

const PromptDetails: FC<TPromptDetailsProps> = ({
  postsCount,
  commentsCount,
  territoryTitle,
  territoryTagColor,
  lensStatsLoading,
}) => (
  <div className="@container w-full">
    <div className="flex @xxxs:flex-col @xs:!flex-row justify-between @xxxs:items-start @xs:!items-center flex-wrap gap-y-2">
      <div className="flex items-center gap-x-4">
        <Typo variant="small-p" className="text-gray-400">
          {postsCount} Posts
        </Typo>
        {!lensStatsLoading && (
          <>
            <DotSeparator />
            <Typo variant="small-p" className="flex items-center gap-x-2 text-gray-400 stroke-gray-400">
              <CommentIcon /> {commentsCount}
            </Typo>
          </>
        )}
      </div>
      {!!territoryTitle && (
        <Typo
          variant="small-p"
          className="h-fit-content px-2 py-1 rounded-[18px]"
          style={{ backgroundColor: `${territoryTagColor}` }}
        >
          t/{territoryTitle}
        </Typo>
      )}
    </div>
  </div>
);
export default PromptDetails;
