import React from 'react';
import cx from 'classnames';
import { Placeholder } from '../Placeholder/Placeholder';
import { ProfileCardWrapper } from './ProfileCardWrapper';

const UserImagePlaceholder = () => <Placeholder className="w-10 h-10 rounded-full" />;
const UsernamePlaceholder = () => <Placeholder className="w-[100px] h-[12px] rounded-md" />;
const UserBioPlaceholder = () => (
  <div className="flex flex-col gap-1">
    <Placeholder className="w-[150px] h-[10px] rounded-md" />
    <Placeholder className="w-[250px] h-[10px] rounded-md" />
    <Placeholder className="w-[200px] h-[10px] rounded-md" />
  </div>
);
const ProfileCardDetailsPlaceholder = () => (
  <div className="flex gap-2 items-center">
    <Placeholder className="w-[50px] h-[10px] rounded-md" />
    <Placeholder className="w-[6px] h-[6px] rounded-full" />
    <Placeholder className="w-[50px] h-[10px] rounded-md" />
    <Placeholder className="w-[6px] h-[6px] rounded-full" />
    <Placeholder className="w-[50px] h-[10px] rounded-md" />
  </div>
);

const TerritoryImagesPlaceholder = () => (
  <ul className="list-none flex flex-row items-center justify-start">
    {Array.from({ length: 5 }).map((_, index) => (
      <li
        className={cx('relative rounded-full w-fit border-2 border-white-100 bg-white-100', {
          '[&:not(:first-of-type)]:ml-[-10px] [&:not(:first-of-type)]:mt-0': true,
          '[&:not(:first-of-type)]:ml-[-10px]': false,
        })}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
      >
        <Placeholder className="w-6 h-6 rounded-full" />
      </li>
    ))}
    <Placeholder className="ml-5 w-[100px] h-[10px] rounded-md" />
  </ul>
);

export const ProfileCardLoader = () => (
  <ProfileCardWrapper>
    <div className="flex gap-5 items-center">
      <UserImagePlaceholder />
      <UsernamePlaceholder />
    </div>
    <UserBioPlaceholder />
    <ProfileCardDetailsPlaceholder />
    <TerritoryImagesPlaceholder />
  </ProfileCardWrapper>
);
