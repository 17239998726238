import { FC } from 'react';
import cx from 'classnames';

type TDotSeparatorProps = {
  className?: string;
};

const DotSeparator: FC<TDotSeparatorProps> = ({ className }) => (
  <div className={cx('hidden md:block h-[6px] w-[6px] bg-gray-300 rounded-full', className)} />
);

export default DotSeparator;
