import { gql } from '@apollo/client';
import { ARTICLE_CARD_FIELDS } from '../../TerritoryArticlesSection/queries/articleCardDataFragment';

const getHomepagePostsFeed = gql`
  ${ARTICLE_CARD_FIELDS}
  query getHomepagePostsFeed($take: Int, $type: ArticleTypeType!, $sortBy: ArticlesOrder) {
    getHomepagePostsFeed(take: $take, type: $type, sortBy: $sortBy) {
      ...ArticleCardFields
    }
  }
`;

export default getHomepagePostsFeed;
