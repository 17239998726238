import React from 'react';

export interface FooterLinkProps {
  text: string;
  href: string;
  download?: string;
  onClick?: () => void;
}

const FooterLink: React.FC<FooterLinkProps> = ({ text, href, download, onClick }) => (
  <a
    className="font-secondary flex justify-content-center cursor-pointer text-[16px] leading-[21px] hover:underline"
    target="_blank"
    href={href}
    rel="noopener noreferrer"
    download={download}
    onClick={onClick}
  >
    {text}
  </a>
);

export default FooterLink;
