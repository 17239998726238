import { useRouter } from 'next/compat/router';
import { useEffect, useState } from 'react';

const isEnabledBasedOnPath = (pathname: string) => {
  const PATHNAMES_WITH_PINS = [
    '/t/[territorySlug]',
    '/t/[territorySlug]/explore',
    '/t/[territorySlug]/explore/posts',
    '/t/[territorySlug]/explore/prompts',
  ];

  return PATHNAMES_WITH_PINS.includes(pathname);
};

export const useIsPinEnabled = () => {
  const router = useRouter();
  const [isPinningEnabled, setIssPinningEnabled] = useState<boolean>(isEnabledBasedOnPath(router?.pathname as string));

  useEffect(() => {
    setIssPinningEnabled(isEnabledBasedOnPath(router?.pathname as string));
  }, [router?.pathname]);

  return { isPinningEnabled };
};
