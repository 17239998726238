import React, { FC } from 'react';
import DropdownItem from '../Dropdown/DropdownItem';
import DropdownClickableItem from '../Dropdown/DropdownClickableItem';
import Typo from '../Typography/Typo';

type TCardDropdownMenuProps = {
  buttons: {
    key: string;
    text: string;
    icon: React.ReactNode;
    onClick: () => void;
  }[];
};

export const CardDropdownMenu: FC<TCardDropdownMenuProps> = ({ buttons }) => (
  <>
    {buttons.map(({ onClick, text, icon, key }) => (
      <DropdownItem key={key} className="relative z-50">
        <DropdownClickableItem leftAdornment={<div className="flex items-center h-6">{icon}</div>} onClick={onClick}>
          <Typo variant="small-p" className="!text-sm">
            {text}
          </Typo>
        </DropdownClickableItem>
      </DropdownItem>
    ))}
  </>
);
