import React, { FC } from 'react';
import { AuthorDetailContainer } from '../ArticleHeader/AuthorDetailContainer';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import Typo from '../Typography/Typo';
import { getMultilineEllipsisStyle } from '../PromptCard/utils/getMultilineEllipsisStyle';
import { T2ProfileCardDetails } from './T2ProfileCardDetails';
import { ProfileCardWrapper } from './ProfileCardWrapper';
import { T2ProfileCardMutualTerritories } from './T2ProfileCardMutualTerritories';
import { TMutualTerritoriesProps, TT2ProfileCardProps } from './types';

export const T2ProfileCard: FC<TT2ProfileCardProps> = ({ user, isCurrentlyLoggedInUser, onClick }) => (
  <ProfileCardWrapper>
    <AuthorDetailContainer
      name={user?.name || ''}
      username={user?.username || ''}
      profileImage={user?.profileImage as TCloudinaryImageShape}
      textSize="small"
      onClick={onClick}
    />
    {user?.bio && (
      <Typo variant="small-p" className="!text-sm text-left multiline-ellipsis" style={getMultilineEllipsisStyle(2)}>
        {user?.bio}
      </Typo>
    )}

    <T2ProfileCardDetails
      timePoints={user?.cumulativeTimePoints as number}
      articleCount={user?.articlesCount as number}
      promtCount={user?.promptsCount as number}
    />
    {!isCurrentlyLoggedInUser && (
      <T2ProfileCardMutualTerritories territories={user?.territories as TMutualTerritoriesProps[]} />
    )}
  </ProfileCardWrapper>
);
