import { useRef, useEffect } from 'react';

export const useResizeTextArea = (data: any) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current != null) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [data]);

  return { textAreaRef };
};
