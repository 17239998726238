import React from 'react';
import Decimal from 'decimal.js';
import { getCommonPropsForPropsProvider } from './getCommonPropsForPropsProvider';
import { TNotificationCardPropsProvider } from '../types';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { NotificationCardTitleWithInitiator } from '../NotificationCardTitleWithInitiator';
import { EarningsIcon } from '../../Icons/EarningsIcon';
import { colors } from '../../Spinner/utils/colors';
import getTerritoryPageUrl from '../../../services/pagesUrl/getTerritoryPageUrl';

export const distributionPropsProvider: TNotificationCardPropsProvider = ({
  notification,
  sendEvent,
  closeAsidePanel,
}) => {
  const { createdAt, onClick } = getCommonPropsForPropsProvider({
    notification,
    sendEvent,
    closeAsidePanel,
  });

  const distribution = notification?.relatedDistributionAllocation?.distribution;
  const epoch = distribution?.epoch;
  const territory = epoch?.territory;

  return {
    createdAt,
    onClick,
    title: (
      <NotificationCardTitleWithInitiator
        content={
          <div>
            Your reward of
            <span className="font-bold">
              {` ${formatCurrency(
                new Decimal(notification?.relatedDistributionAllocation?.currencyAllocation || 0).toNumber(),
              )} $USDC`}
            </span>{' '}
            in <span className="font-bold">{`t/${territory?.name}`}</span> is ready to withdraw.
          </div>
        }
      />
    ),
    leftAdornment: (
      <div className="flex-shrink-0 w-8 h-8 bg-t2-success-lightGreen rounded-full flex items-center justify-center">
        <EarningsIcon stroke={colors.t2.success.darkGreen} className="w-4 h-4" />
      </div>
    ),
    // TODO: add territory slug to url
    url: getTerritoryPageUrl(territory?.slug as string),
  };
};
