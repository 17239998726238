import isValidFileSize from 't2-keystone/utils/isValidFileSize';
import { MAX_FILE_SIZE_ALLOWED_IN_MB } from 't2-keystone/src/constants';
import { CloudinaryImage_File } from 't2-graphql-types';
import isCloudinaryImageFile from '../../../../utils/isCloudinaryImageFile';
import { showNotification } from '../../NotificationBanner/showNotification';

export const validateInputImage = (files: File[] | CloudinaryImage_File) => {
  if (files && isCloudinaryImageFile(files)) {
    return true;
  }

  const selectedFile = files?.[0];

  if (selectedFile?.size && !isValidFileSize(selectedFile)) {
    showNotification({
      message: `Image size exceeds ${MAX_FILE_SIZE_ALLOWED_IN_MB}MB.`,
      type: 'error',
    });
    return false;
  }
  return true;
};
