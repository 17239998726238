import { gql } from '@apollo/client';

export const updateTerritoryPinnedAtQuery = gql`
  mutation updateTerritoryPinnedAt($id: ID!, $data: ArticleUpdateInput!) {
    updateArticle(where: { id: $id }, data: $data) {
      id
      territoryPinnedAt
    }
  }
`;
