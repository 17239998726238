import { PromptSettingsLiveStatus } from '../../Prompt/constants';

export const getPromptTooltipLabel = (status: PromptSettingsLiveStatus) => {
  switch (status) {
    case PromptSettingsLiveStatus.LIVE:
      return 'Live';
    case PromptSettingsLiveStatus.UPCOMING:
      return 'Upcoming';
    case PromptSettingsLiveStatus.COMPLETED:
      return 'Completed';
    default:
      return null;
  }
};
