import React, { FC } from 'react';
import Link from 'next/link';
import cx from 'classnames';
import Typo from '../Typography/Typo';
import { formatDate } from '../../../utils/formatDate';
import CommentIcon from '../CommentsSection/Icons/CommentIcon';
import Tooltip from '../Tooltip/Tooltip';
import { ArticleCardDetailsListItem } from './ArticleCardDetailsListItem';
import { ArticleCardDetailsList } from './ArticleCardDetailsList';

type TArticleCardDetailsProps = {
  hasRead?: boolean;
  totalTimePointsAccumulated: string;
  commentsCount: number;
  readTime: string;
  publishedAt: string;
  createdAt: string;
  lensStatsLoading?: boolean;
  url: string;
};

export const ArticleCardDetails: FC<TArticleCardDetailsProps> = ({
  hasRead,
  totalTimePointsAccumulated,
  commentsCount,
  readTime,
  publishedAt,
  createdAt,
  lensStatsLoading,
  url,
}) => {
  const ARTICLE_CARD_DETAILS_ITEMS = [
    {
      id: '1',
      element: (
        <Typo
          variant="p2"
          text={`${formatDate(publishedAt || createdAt)}`}
          className={cx('!text-p4 text-black-700', {
            '!text-gray-400': hasRead,
          })}
        />
      ),
      shouldDisplay: true,
    },
    {
      id: '2',
      element: (
        <Typo
          variant="p2"
          className={cx('flex items-center gap-x-2 !text-p4 stroke-black-700 text-black-700', {
            '!text-gray-400': hasRead,
          })}
        >
          <CommentIcon color={hasRead ? '#929292' : ''} /> {commentsCount || 0}
        </Typo>
      ),
      shouldDisplay: !lensStatsLoading,
    },
    {
      id: '3',
      element: (
        <Tooltip
          trigger={
            <Link className="relative z-10" href={url}>
              <Typo
                variant="p2"
                text={`${Math.floor(+totalTimePointsAccumulated)} t.p.`}
                className={cx('!text-p4 text-black-700', {
                  '!text-gray-400': hasRead,
                })}
              />
            </Link>
          }
          direction="bottom"
        >
          <Typo variant="small-p">
            Time Points (t.p.) reflect the aggregated activity from people reading and writing this post.
          </Typo>
        </Tooltip>
      ),
      shouldDisplay: totalTimePointsAccumulated,
    },
    {
      id: '4',
      element: (
        <Typo
          variant="p2"
          className={cx('!text-p4 text-black-700', {
            '!text-gray-400': hasRead,
          })}
        >
          {readTime} min read
        </Typo>
      ),
      shouldDisplay: true,
    },
  ];

  return (
    <div className="flex flex-col @2xl:flex-row mt-auto gap-3">
      <ArticleCardDetailsList>
        {ARTICLE_CARD_DETAILS_ITEMS.slice(0, 3)
          .filter(({ shouldDisplay }) => shouldDisplay)
          .map((item, index) => {
            const isFirst = index === 0;

            return (
              <ArticleCardDetailsListItem key={item.id} isFirst={isFirst}>
                {item.element}
              </ArticleCardDetailsListItem>
            );
          })}
      </ArticleCardDetailsList>

      <ArticleCardDetailsList>
        {ARTICLE_CARD_DETAILS_ITEMS.slice(3)
          .filter(({ shouldDisplay }) => shouldDisplay)
          .map((item, index) => {
            const isFirst = index === 0;

            return (
              <ArticleCardDetailsListItem key={item.id} isFirst={isFirst} showDotSeparator>
                {item.element}
              </ArticleCardDetailsListItem>
            );
          })}
      </ArticleCardDetailsList>
    </div>
  );
};
