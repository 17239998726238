import { useMemo, useEffect } from 'react';
import { Post, PublicationId, useLazyPublications, LimitType } from '@lens-protocol/react-web';
import { Article } from 't2-graphql-types';
import isEmpty from 'lodash/isEmpty';
import { useActiveLensProfile } from './useActiveLensProfile';
import { TArticleWithLensPublication } from '../types';

const getArticlesPublishedOnLens = (articles: Article[] | undefined) =>
  articles?.filter((article) => article?.lensPublicationId)?.map((article) => article?.lensPublicationId);

const addLensCommentsCount = <T extends Article>(articles: T[], lensPosts: Post[]): T[] => {
  if (!lensPosts || !articles) {
    return articles;
  }

  return articles.map((article) => {
    const post = lensPosts.find((item) => item.id === article.lensPublicationId);
    const lensCommentsCount = Number(post?.stats?.comments);
    const t2CommentsCount = Number(article?.commentsCount);

    return {
      ...article,
      commentsCount: t2CommentsCount + (lensCommentsCount || 0),
    };
  });
};

export const useGetLensPublicationsStats = (articles: Article[] | undefined) => {
  const { loading: isActiveProfileLoading } = useActiveLensProfile();
  const lensPublicationIds = useMemo(() => getArticlesPublishedOnLens(articles), [articles]);
  const { execute: getPublications, data: publications, loading, error } = useLazyPublications();

  useEffect(() => {
    if (!isEmpty(lensPublicationIds) && !isActiveProfileLoading) {
      getPublications({
        limit: LimitType.Fifty,
        where: {
          publicationIds: lensPublicationIds as PublicationId[],
        },
      });
    }
  }, [lensPublicationIds, isActiveProfileLoading]);

  const articlesWithLensPublications: TArticleWithLensPublication[] | undefined = articles?.map(
    ({ lensPublicationId, ...article }) => ({
      ...article,
      lensPublicationId,
      lensPublication: lensPublicationId
        ? publications?.find((publication) => publication.id === lensPublicationId)
        : undefined,
    }),
  );
  const mappedData = addLensCommentsCount(articlesWithLensPublications || [], (publications as Post[]) || []);

  return {
    articlesWithLensStats: mappedData,
    lensStatsLoading: loading,
    error,
  };
};
