export type TUnsafeCharactersErrorMessageAllowParam = {
  allowUpperCase: boolean;
  allowLowerCase: boolean;
  allowDigits: boolean;
  allowedSpecialChars: string[];
};

const defaultAllowParam: TUnsafeCharactersErrorMessageAllowParam = {
  allowUpperCase: false,
  allowLowerCase: true,
  allowDigits: true,
  allowedSpecialChars: ['-', '_'],
};

export const unsafeCharactersErrorMessage = (
  fieldName: string,
  allowParam: Partial<TUnsafeCharactersErrorMessageAllowParam> = {},
) => {
  const { allowUpperCase, allowLowerCase, allowDigits, allowedSpecialChars } = { ...defaultAllowParam, ...allowParam };

  const charsText = [
    allowUpperCase && 'A-Z',
    allowLowerCase && 'a-z',
    allowDigits && '0-9',
    ...allowedSpecialChars,
  ].filter(Boolean);

  return `${fieldName} should not contain unsafe characters. Safe characters are ${charsText.join(', ')}`;
};
