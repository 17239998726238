import { ForwardedRef, forwardRef } from 'react';
import cx from 'classnames';
import { Textarea } from 'flowbite-react';
import { useResizeTextArea } from './hooks/useResizeTextArea';

type TAutoSizeTextAreaProps = React.HTMLProps<HTMLTextAreaElement>;

const safelyAssignRef = (ref: ForwardedRef<HTMLTextAreaElement>, node: HTMLTextAreaElement | null) => {
  if (typeof ref === 'function') {
    ref(node);
  } else if (ref) {
    // eslint-disable-next-line no-param-reassign
    ref.current = node;
  }
};

export const AutoSizeTextArea = forwardRef<HTMLTextAreaElement, TAutoSizeTextAreaProps>(
  ({ value, className, ...props }, ref) => {
    const { textAreaRef } = useResizeTextArea(value);

    return (
      <Textarea
        color="t2-textarea"
        ref={(node) => {
          // @ts-ignore
          textAreaRef.current = node;
          safelyAssignRef(ref, node);
        }}
        value={value}
        className={cx('overflow-hidden resize-none', className)}
        {...props}
      />
    );
  },
);
