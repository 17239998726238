import { KEYSTONE_URL } from 'config';
import { getFeatureFlag } from 'src/components/FeatureFlags/getFeatureFlag';
import { getArticleEditPageUrl } from 'src/services/pagesUrl/gerArticleEditPageUrl';

const getManageArticleUrl = (id: string) => {
  if (getFeatureFlag('articleEditor')) {
    return getArticleEditPageUrl(id);
  }

  return `${KEYSTONE_URL}/articles/${id}`;
};

export default getManageArticleUrl;
