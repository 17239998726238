import Image from 'next/legacy/image';
import { CSSProperties } from 'react';

type TPreviousArrowProps = {
  onClick?: () => void;
  style?: CSSProperties;
  className?: string;
};

export const PreviousArrow = ({ onClick, style, className }: TPreviousArrowProps) => (
  <span className={className} onClick={onClick} style={style}>
    <Image src="/images/previous-arrow.svg" width="28" height="28" className="cursor-pointer" />
  </span>
);
