import React, { FC } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import { UserDetail } from '../UserDetail/UserDetail';
import Typo from '../Typography/Typo';
import PromptDetails from './PromptDetails';
import { getMultilineEllipsisStyle } from './utils/getMultilineEllipsisStyle';
import { hoverAnimationClassNames } from '../../../utils/hoverAnimationClassNames';
import { TPromptCardProps } from './types';
import { inTextEllipsisCenter } from '../../../utils/text-ellipsis-center';
import { PromptCardStatusLabel } from './PromptCardStatusLabel';
import { formatPromptSettingsDate } from './utils/formatPromptSettingsDate';
import { PromptSettingsLiveStatus } from '../Prompt/constants';
import { PinIcon } from '../Icons/PinIcon';
import Dropdown from '../Dropdown/Dropdown';
import ThreeDotsIcon from '../Icons/ThreeDotsIcon';
import { CardDropdownMenu } from '../CardDropdownMenu/CardDropdownMenu';

export const PromptCard: FC<TPromptCardProps> = ({
  title,
  postsCount,
  commentsCount,
  authorLabel,
  authorProfileURL,
  authorProfileImage,
  url,
  territoryTitle,
  territoryTagColor,
  lensStatsLoading,
  onClick,
  promptSettings,
  showLivePrompts,
  isPinningEnabled,
  onPinClick,
  territoryPinnedAt,
  canManagePinnedArticles,
  onDropdownStageChange,
}) => {
  const dropdownMenuButtons = [
    {
      key: 'pin',
      text: territoryPinnedAt ? 'Unpin Prompt' : 'Pin Prompt',
      icon: <PinIcon className="w-4 h-4 self-center fill-none stroke-t2-neutral-grey09" />,
      onClick: () => {
        onPinClick?.();
      },
    },
  ];

  return (
    <div
      className={cx(
        'h-full grid relative w-full cursor-pointer hover:shadow-hoverShadow hover:-translate-y-[2px] rounded-xl',
        hoverAnimationClassNames,
        {
          'border-2 border-t2-success-neonGreen':
            showLivePrompts && promptSettings?.liveStatus === PromptSettingsLiveStatus.LIVE,
        },
      )}
    >
      <Link onClick={onClick} href={url} className="absolute w-full h-full z-10">
        {' '}
      </Link>
      <div className="grid content-between px-5 py-6 flex-col gap-y-5 bg-white-900 rounded-xl">
        <div className="flex flex-1 min-w-1 flex-col gap-y-5">
          <div className="relative w-full flex items-center justify-between">
            <div className="z-20">
              <UserDetail
                displayName={promptSettings ? inTextEllipsisCenter(authorLabel, 4, 4) : authorLabel}
                href={authorProfileURL}
                profileImage={authorProfileImage}
              />
            </div>
            {showLivePrompts && promptSettings?.startDate && (
              <div className="ml-3 z-20">
                <Link href={url}>
                  <PromptCardStatusLabel
                    status={promptSettings?.liveStatus as PromptSettingsLiveStatus}
                    startDate={formatPromptSettingsDate(promptSettings?.startDate) as string}
                    endDate={formatPromptSettingsDate(promptSettings?.endDate) as string}
                  />
                </Link>
              </div>
            )}
          </div>
          <Typo
            variant="p2"
            className="text-gray-700 cursor-pointer multiline-ellipsis break-words"
            style={getMultilineEllipsisStyle(3)}
          >
            {title}
          </Typo>
        </div>
        <div className="flex items-center justify-between">
          <PromptDetails
            territoryTitle={territoryTitle}
            territoryTagColor={territoryTagColor}
            postsCount={postsCount!}
            commentsCount={commentsCount!}
            lensStatsLoading={lensStatsLoading}
          />
          <div className="flex items-center gap-x-4 h-6">
            {isPinningEnabled && territoryPinnedAt && (
              <PinIcon className="stroke-t2-primary-brandColor fill-t2-primary-brandColor" />
            )}
            {isPinningEnabled && canManagePinnedArticles && (
              <div className="z-20 max-h-5">
                <Dropdown
                  onStateChange={onDropdownStageChange}
                  label={<ThreeDotsIcon />}
                  menu={<CardDropdownMenu buttons={dropdownMenuButtons} />}
                  position="right"
                  menuContainerClassName="bottom-[35px] border border-t2-neutral-grey02 shadow-hoverShadow"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
