import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import DotSeparator from '../CommentsSection/Comment/DotSeparator';

type TArticleCardDetailsListItemProps = {
  isFirst?: boolean;
  children: ReactNode;
  showDotSeparator?: boolean;
};

export const ArticleCardDetailsListItem: FC<TArticleCardDetailsListItemProps> = ({
  isFirst,
  showDotSeparator,
  children,
}) => (
  <li className="flex items-center gap-x-3 whitespace-nowrap">
    <div
      className={cx({
        'hidden @xl:hidden': isFirst,
        '@2xl:!block': showDotSeparator,
      })}
    >
      <DotSeparator />
    </div>
    {children}
  </li>
);
