'use client';

import { useMutation } from '@apollo/client';
import { NavbarModalContextProvider } from '../NavbarModalComponent/context/NavbarModalContext';
import Navbar, { INavbarProps } from './Navbar';
import { AsidePanelContextProvider } from '../AsidePanel/contexts/AsidePanelContext';
import { readAllNotificationsMutation } from '../Notification/mutation/readAllNotificationsMutation';
import { useAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { getNewNotificationsCountQuery } from '../Notification/query/getNewNotificationsCountQuery';
import { enableGlobalScrollbar } from '../Modal/utils/enableGlobalScrollbar';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';

const NavbarContainer = (props: INavbarProps) => {
  const { authenticatedUserId } = useAuthenticatedUser();
  const [readAllNotifications] = useMutation(readAllNotificationsMutation, {
    variables: { recipientId: authenticatedUserId },
    refetchQueries: [getNewNotificationsCountQuery],
  });
  const { sendEvent } = useAnalytics();

  const notificationAsidePanelCloseHandler = () => {
    sendEvent('notifications_panel_close');
    readAllNotifications();
    enableGlobalScrollbar();
  };

  return (
    <AsidePanelContextProvider onClose={notificationAsidePanelCloseHandler}>
      <NavbarModalContextProvider>
        <Navbar {...props} />
      </NavbarModalContextProvider>
    </AsidePanelContextProvider>
  );
};

export default NavbarContainer;
