import React from 'react';
import Typo from '../Typography/Typo';
import Tooltip from '../Tooltip/Tooltip';
import InformationIcon from '../Icons/InformationIcon';

const HomepageTerritoriesTitle = () => (
  <div className="flex items-center gap-x-3">
    <Typo variant="p1" className="!text-[22px] lg:!text-[28px]">
      Explore Territories
    </Typo>
    <Tooltip direction="right" trigger={<InformationIcon color="#929292" height="20" width="20" />}>
      <Typo
        variant="p2"
        text="Territories are common grounds for ideas. Time Points (t.p.) reflect the aggregated reading and writing activity in the Territory."
        className="!text-p4 max-w-[280px] whitespace-normal  text-gray-100"
      />
    </Tooltip>
  </div>
);

export default HomepageTerritoriesTitle;
