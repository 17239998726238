import { forwardRef } from 'react';
import cx from 'classnames';
import Fieldset from '../Fieldset/Fieldset';
import { TTextInputProps } from './types';

const TextInput = forwardRef<HTMLInputElement, TTextInputProps>(
  (
    {
      className,
      label,
      placeholder,
      disabled,
      required,
      errorMessage,
      inputClassName,
      readOnly,
      labelRightAdornment,
      inputRightAdornment,
      ...props
    },
    ref,
  ) => (
    <Fieldset
      disabled={disabled}
      required={required}
      className={className}
      errorMessage={errorMessage}
      label={label}
      labelRightAdornment={labelRightAdornment}
      readOnly={readOnly}
    >
      <input
        ref={ref}
        className={cx(
          'w-full h-full pb-5 pt-12 px-4 bg-white-900 border border-gray-200 focus:outline-none rounded-[8px] disabled:text-gray-400 focus:!ring-0',
          {
            'focus:border-gray-400': !errorMessage,
            'border-red-800': !!errorMessage,
          },
          inputClassName,
        )}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        {...props}
      />
      {inputRightAdornment && (
        <div className="absolute right-1 xs:right-4 top-1/2 transform -translate-y-1/2">{inputRightAdornment}</div>
      )}
    </Fieldset>
  ),
);

export default TextInput;
