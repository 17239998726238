import { gql } from '@apollo/client';
import { NOTIFICATION_WHERE_CONDITION } from './constants';

export const getNewNotificationsQuery = gql`
  query getNewNotifications($recipientId: ID!, $skip: Int, $take: Int) {
    notifications(
      where: ${NOTIFICATION_WHERE_CONDITION}
      skip: $skip
      take: $take
      orderBy: { createdAt: desc }
    ) {
      id
      recipient {
        id,
      }
      type
      createdAt
      initiator {
        id
        name
        username
        publicAddress
        profileImage {
          id
          publicUrl
          publicUrlTransformed
          _meta {
            public_id
            height
            width
          }
        }
      }
      relatedArticle {
        id
        title
        slug
        type
        author {
          id
        }
        parentArticle {
          id
          type          
          body          
        }
        coverImage {
          id
          publicUrl
          publicUrlTransformed
          _meta {
            public_id
            height
            width
          }
        }
        territory {
          id
          slug
        }
        coverImage {
          id
          publicUrl
          publicUrlTransformed
          _meta {
            public_id
            height
            width
          }
        }
      }
      relatedComment {
        id
        content
        article {
          id
          slug
          type
        }
      }
      relatedCollect {
        id
        transactionStatus
      }
      relatedMembership {
        id
        role {
          id
          name
          territory {
          id
          name
          slug
        }
        }  
      }      
      relatedDistributionAllocation {
        id
        currencyAllocation
        distribution {
          id
          epoch {
            id
            territory {
                id
                name
                slug
            }
          }
        }
      }
    }
    notificationsCount(where: ${NOTIFICATION_WHERE_CONDITION})
  }
`;
