import React, { FC } from 'react';
import ConfirmationModalContent from './ConfirmationModalContent';
import { TGetModalComponent } from '../Modal/useModal';
import ConfirmationModalActions from './ConfirmationModalActions';

type TCloseConfirmationModalProps = {
  ModalComponent: TGetModalComponent;
  closeModal: () => void;
  openModal: () => void;
  closeConfirmationModal: () => void;
  isLoading?: boolean;
};

export const CloseConfirmationModal: FC<TCloseConfirmationModalProps> = ({
  ModalComponent,
  closeModal,
  openModal,
  closeConfirmationModal,
  isLoading,
}) => {
  const handleConfirm = () => {
    closeModal();
    closeConfirmationModal();
  };

  const handleCancel = () => {
    closeConfirmationModal();
    openModal();
  };

  return (
    <ModalComponent
      title="Your changes won’t be saved"
      containerClassName="max-w-lg top-[25%]"
      footer={
        <ConfirmationModalActions
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          isLoading={isLoading}
          cancelButtonText="Continue editing"
          confirmationButtonText="Leave"
        />
      }
    >
      <ConfirmationModalContent body="Are you sure that you want to leave the current modal? Any  changes will be lost. " />
    </ModalComponent>
  );
};
