import React, { FC, useState } from 'react';
import cx from 'classnames';
import DropdownLabel, { TDropdownLableBackgroundColorVariant } from '../Dropdown/DropdownLabel';
import Dropdown from '../Dropdown/Dropdown';
import { SortingDropdownItems } from './SortingDropdownItems';
import ChevronIcon from '../Navbar/ChevronIcon';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { TDropdownState } from '../Dropdown/types';
import { TSortingDropdownItems } from './types';

type TSortingDropdownProps = {
  items: TSortingDropdownItems[];
  onSortByChange?: (sortBy: string) => void;
  purpose: string;
  territoryId?: string;
  defaultSortBy?: string;
  dropdownMenuPosition?: 'right';
  dropdownLabelClassName?: string;
  dropdownLabelbackgroundColor?: TDropdownLableBackgroundColorVariant;
};

export const SortingDropdown: FC<TSortingDropdownProps> = ({
  items,
  onSortByChange,
  purpose,
  territoryId,
  defaultSortBy,
  dropdownMenuPosition,
  dropdownLabelClassName,
  dropdownLabelbackgroundColor,
}) => {
  const { sendEvent } = useAnalytics();
  const [sortBy, setSortBy] = useState<string>(defaultSortBy || items?.[0]?.name || '');

  const handleStateChange = ({ isOpen }: TDropdownState) => {
    const sendEventProps = {
      ...(territoryId ? { territory_id: territoryId } : {}),
      purpose,
    };

    if (isOpen) {
      sendEvent('sort_prompts_dropdown_open', sendEventProps);
    } else {
      sendEvent('sort_prompts_dropdown_close', sendEventProps);
    }
  };

  const onChange = (sortBy: string) => {
    setSortBy(sortBy);
    onSortByChange?.(sortBy);
  };

  return (
    <Dropdown
      menuContainerClassName="min-w-[240px] !z-30 shadow-staticShadow"
      onStateChange={handleStateChange}
      renderLabel={({ isOpen }) => (
        <DropdownLabel
          className={cx(dropdownLabelClassName)}
          backgroundColor={dropdownLabelbackgroundColor}
          labelClassName="!text-[14px]"
          label={items?.find(({ name }) => name === sortBy)?.label || ''}
          rightAdornment={
            <ChevronIcon
              className={cx('mr-1 ml-2 stroke-t2-neutral-grey09', {
                'scale-y-1 transition-all duration-200': !isOpen,
                'scale-y-[-1] transition-all duration-200': isOpen,
              })}
              height={6}
              width={12}
            />
          }
          isActive={isOpen}
        />
      )}
      position={dropdownMenuPosition}
      menu={
        <SortingDropdownItems
          items={items}
          value={sortBy}
          onChange={onChange}
          purpose={purpose}
          territoryId={territoryId}
        />
      }
    />
  );
};
