import React, { FC, ReactNode } from 'react';

type TProfileCardWrapperProps = {
  children: ReactNode;
};

export const ProfileCardWrapper: FC<TProfileCardWrapperProps> = ({ children }) => (
  <div className="w-[300px] flex flex-col p-4 gap-4 bg-t2-shades-white border border-t2-neutral-grey02 rounded-[8px] shadow-staticShadow">
    {children}
  </div>
);
