import cx from 'classnames';
import { HTMLProps, ReactNode } from 'react';

type TProps = HTMLProps<HTMLLIElement> & { children: ReactNode };

const ArticleInteractionsProfilesListElement = ({ className, ...props }: TProps) => (
  <li
    className={cx('relative rounded-full w-min border-2 border-white-100 bg-white-100 z-0 hover:!z-10', className)}
    {...props}
  />
);

export default ArticleInteractionsProfilesListElement;
