import { FC } from 'react';
import { useMutation } from '@apollo/client';
import { TPromptCardProps } from './types';
import { PromptCard } from './PromptCard';
import { getFeatureFlag } from '../FeatureFlags/getFeatureFlag';
import { useReactiveHasPermission } from '../Permissions/hooks/useHasPermission';
import { updateTerritoryPinnedAtQuery } from '../Territory/mutations/updateTerritoryPinnedAtQuery';
import { useIsPinEnabled } from '../Territory/hooks/useIsPinEnabled';
import { showNotification } from '../NotificationBanner/showNotification';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { MAX_PINNED_PROMPTS } from './constants';

export const PromptCardContainer: FC<TPromptCardProps> = ({ id, lensPublication, lensStatsLoading, ...props }) => {
  const showLivePrompts = getFeatureFlag('livePrompts');
  const hasPermission = useReactiveHasPermission();
  const { isPinningEnabled } = useIsPinEnabled();
  const { sendEvent } = useAnalytics();

  const [updateTerritoryPinnedAt] = useMutation(updateTerritoryPinnedAtQuery, {
    variables: {
      id,
      data: {
        territoryPinnedAt: props?.territoryPinnedAt ? null : new Date().toISOString(),
      },
    },
  });

  const canManagePinnedArticles =
    hasPermission('GLOBAL_CAN_MANAGE_PINNED_ARTICLES') ||
    hasPermission('TERRITORY_CAN_MANAGE_PINNED_ARTICLES', props?.territoryId);

  const toggleTerritoryPin = async () => {
    try {
      await updateTerritoryPinnedAt();
      sendEvent('prompt_card_pin_toggle', { prompt_id: id, value: !props?.territoryPinnedAt });
    } catch (e) {
      sendEvent('prompt_card_pin_error', { promt_id: id });
      if ((e as any).message.includes(MAX_PINNED_PROMPTS)) {
        showNotification({
          message: 'You can only have 3 Prompts pinned.',
          type: 'error',
          title: 'You’re out of Pins',
          displayIcon: false,
        });
      }
    }
  };

  const onDropdownStageChange = () => {
    sendEvent('prompt_card_dropdown_click', { prompt_id: id });
  };

  return (
    <PromptCard
      {...props}
      lensStatsLoading={lensStatsLoading}
      id={id}
      showLivePrompts={showLivePrompts}
      isPinningEnabled={isPinningEnabled}
      canManagePinnedArticles={canManagePinnedArticles}
      onPinClick={toggleTerritoryPin}
      onDropdownStageChange={onDropdownStageChange}
    />
  );
};
