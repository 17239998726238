import React, { FC } from 'react';
import HeroSlideWrapper from './HeroSlideWrapper';
import HeroSlideContentWrapper from './HeroSlideContentWrapper';
import Typo from '../Typography';
import LinkButton from '../LinkButton/LinkButton';
import { THeroSlideProps } from './types';
import { THP_V2_POST_LINK } from './constants';

type THPv2SlideProps = THeroSlideProps;

export const THPv2Slide: FC<THPv2SlideProps> = ({ onButtonClick }) => (
  <HeroSlideWrapper className="md:!bg-t2-primary-beige relative justify-between px-5 md:px-14 xl:px-24 before:absolute before:inset-0 before:bg-thp-v2-mobile before:sm:bg-thp-v2-tablet before:xl:bg-thp-v2-desktop before:rounded-[24px] before:-z-0 before:bg-no-repeat before:bg-cover">
    <HeroSlideContentWrapper className="mt-40 md:mt-0 relative items-center sm:items-start basis-full md:basis-3/4 xl:basis-2/3">
      <div className="flex flex-col gap-4 justify-center sm:justify-start">
        <Typo
          variant="small-p"
          className="!text-3xl md:!text-5xl xl:!text-6xl !font-primary !font-semibold text-t2-neutral-grey08"
        >
          <span className="italic">Explore your new</span>
          <br /> Territory Home Page
        </Typo>
        <Typo variant="small-p" className="!text-xl md:!text-2xl xl:!text-[28px] !font-semibold">
          Meet Territory V2
        </Typo>
      </div>
      <Typo variant="p2" className="text-left !text-base md:!text-lg text-t2-neutral-grey07">
        We have given your Territories a fresh look! Check out your favourite communities and explore the newly
        renovated spaces now.
      </Typo>
      <LinkButton
        onClick={onButtonClick}
        variant="secondary"
        className="!px-12 !text-t2-neutral-grey08 !bg-[#FF7ECB]"
        href={THP_V2_POST_LINK}
      >
        Tell me all about it
      </LinkButton>
    </HeroSlideContentWrapper>
  </HeroSlideWrapper>
);
