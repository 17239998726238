import Image from 'next/legacy/image';
import { CSSProperties } from 'react';

type TNextArrowProps = {
  onClick?: () => void;
  style?: CSSProperties;
  className?: string;
};

export const NextArrow = ({ onClick, style, className }: TNextArrowProps) => (
  <span className={className} onClick={onClick} style={style}>
    <Image src="/images/next-arrow.svg" width="28" height="28" className="cursor-pointer" />
  </span>
);
