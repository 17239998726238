import React from 'react';
import { TNotificationCardPropsProvider } from '../types';
import { getCommonPropsForPropsProvider } from './getCommonPropsForPropsProvider';
import { NotificationCardTitleWithInitiator } from '../NotificationCardTitleWithInitiator';
import { ShieldIcon } from '../../Icons/ShieldIcon';
import { getTerritoryCommunityPageUrl } from '../../../services/pagesUrl/getTerritoryCommunityPageUrl';

export const membershipExpiredPropsProvider: TNotificationCardPropsProvider = ({
  notification,
  sendEvent,
  closeAsidePanel,
}) => {
  const { createdAt, onClick } = getCommonPropsForPropsProvider({
    notification,
    sendEvent,
    closeAsidePanel,
  });

  const { name, territory } = notification?.relatedMembership?.role || {};

  return {
    createdAt,
    onClick,
    title: (
      <NotificationCardTitleWithInitiator
        content={
          <div>
            Your membership role <span className="font-bold">{name}</span> in{' '}
            <span className="font-bold">{territory?.name}</span> has expired. Renew your membership role to regain
            access to your access and permissions.
          </div>
        }
      />
    ),
    leftAdornment: (
      <div className="flex-shrink-0 w-8 h-8 bg-t2-error-lightRed rounded-full flex items-center justify-center text-t2-error-darkRed">
        <ShieldIcon className="!w-5 !h-5" />
      </div>
    ),
    url: `${getTerritoryCommunityPageUrl(territory?.slug)}?community-section=membership-roles`,
  };
};
